body.menu-main-1--active {
    overflow-y: hidden;
    .menu-main-1__listitems-0 {
        > .menu-main-1__item {
            overflow-y: auto;
            height: 100%;
        }
    }
}

.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: none;
        &[aria-hidden="false"] {
            display: block;
        }
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    z-index: 100;
    position: static;

}
.menu-main-1 {
    .wrapper-main {
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        //position: absolute;
        position: fixed;
        z-index: 101;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        transition: transform ease .3s;
        transform: translate(100%, 0);
        width: 100%;
        background: $color-1--2;
        > .menu-main-1__header {
            display: block;
            background: $color-white;
            padding: .8em 0;
            > span {
                display: block;
                text-transform: uppercase;
                font-size: 1.9em;
                color: $color-black;
                padding: 0 0 0 4.5rem;
            }
            .menu-main-1__action {
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                position: absolute;
                top: 1.6em;
                left: -4rem;
                width: 4rem;
                height: 4rem;
                background: $color-3--1;
                transition: left ease .3s, top ease .3s;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    content: "☰";
                    font-size: 1.3em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                }
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            &[aria-hidden="false"] {
                display: block;
            }
            > .menu-main-1__closing-button {
                display: block;
                top: 0;
                right: 0;
            }
        }
        &.menu-main-1__item--active {
            transform: translate(0, 0);
            > .menu-main-1__header {
                .menu-main-1__action {
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    background: $color-1--2;
    > li {
        display: block;
        vertical-align: inherit;
        border-bottom: 1px solid $color-white;
        > .menu-main-1__header {
            > a {
                color: $color-white;
                padding: .5em;
                position: relative;
                &:after {
                    position: absolute;
                    top: 50%;
                    right: .5rem;
                    transform: translateY(-50%);
                    content: "+";
                    font-size: 1.3em;
                    font-weight: $light;
                    display: block;
                }
                &[aria-expanded="true"] {
                    &:after {
                        content: "-"
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            position: static;
            max-height: inherit;
            display: none;
            .menu-main-1__listitems-1__wrapper {
                background: darken($color-1--2, 5%);
                transform: inherit;
                height: auto;
                padding: 0;
            }
            &[aria-hidden="false"] {
                display: block;
                max-height: inherit;
                .menu-main-1__listitems-1__wrapper {
                    transform: inherit;
                    transition: inherit;
                }
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 100%;
    > li {
        // Level 2 and 3
        .menu-main-1__header {
            .menu-main-1__action {
                &:before {
                    content: "+";
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "-"
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                padding-top: .5em;
                padding-bottom: .5em;
                padding-left: 1em;
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: darken($color-1--2, 10%);
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
            }
        }
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding: .5em 0;
    > li {
        > .menu-main-1__header {
            a {
                padding-top: .4em;
                padding-bottom: .4em;
                padding-left: 1.5em;
            }
        }
        > .menu-main-1__sublevel {
            display: none;
            position: static;
            width: 100%;
            transform: inherit;
            height: 0;
            transition: inherit;
            background: darken($color-1--2, 15%);
            &[aria-hidden="false"] {
                display: block;
                transform: inherit;
                height: auto;
                transition: inherit;
            }
        }
    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: .5em 0;
    > li {
        > .menu-main-1__header {
            a {
                padding-top: .3em;
                padding-bottom: .3em;
                padding-left: 1.7em;
            }
        }
    }
}
