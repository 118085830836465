// @name Header page
.header {
    position: relative;
    z-index: 12;
    &-banner {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &-top-bar {
        @extend .wrapper-main;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 61px;
        &:after,
        &:before {
            display: none;
        }
    }
    &-bar {
        margin: -45px 0;

        &:only-child {
            margin: 0 0 -130px;
            background-color: transparent;
        }

        .home & {
            //position: absolute;
            background-color: transparent;
            margin-bottom: -45px;
            //margin: -45px 0;
            //bottom: -35px;
            //left: 50%;
            //transform: translate(-50%, 0);
            &--no-slider {
                margin: -45px 0 0;
            }
         }
        .home & {
            //bottom: -54px;
            //max-width: 1240px;
        }
        &-inner {
            @extend .wrapper-main;
            background: url(#{$image-bg-url}bg-decor-01.png);
            padding: 10px;
            position: relative;
            z-index: 100;
            display: flex;
            align-items: center;
            &:before {
                background: #384249;
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                z-index: -1;
                content: '';
            }

            &--no-banner {
                margin-top: 4rem;
            }
        }
    }
}

.page {
    overflow: hidden;
    position: relative;
}

// @name Top of content
.top-of-content {
    background: $color-3--1;
    //position: relative;
    //&:after {
    //    content: '';
    //    width: 100%;
    //    height: 100%;
    //    left: 0;
    //    top: 0;
    //    position: absolute;
    //    background-color: $color-3--1;
    //    z-index: -2;
    //}

    &--with-banner {
        .top-of-content__wrapper {
            padding-top: 57px;
        }
    }
}

.top-of-content__wrapper {
    display: flex;
    align-items: center;
    @extend .wrapper-main;
    //padding: 20px 1rem;
    padding: 145px 1rem 19px;
    max-width: 118rem;
    position: relative;
    z-index: 11;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        height: 1px;
        background: $color-2--2;
        z-index: 9;
    }
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 60px;
    padding-bottom: 3em;
    position: relative;
    z-index: 10;
}

.section-main__wrapper-2 {
    display: flex;
    width: 100%;
}

// @name Main section content
.section-main__content {
    width: 100%;

    &:only-child {
        max-width: calc(100% - 35rem);
        margin-left: auto;
        margin-right: auto;
    }
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background: $color-1--2 url(#{$image-bg-url}bg-footer-pattern.png);
    .footer__wrapper {
        @extend .wrapper-main;
        padding-top: 3.7em;
        padding-bottom: 3em;
    }
    .footer__wrapper-2 {
        display: flex;
        width: 100%;
    }
}

.page {
    position: relative;
    .header-bar-inner {
        .language {
            display: none;
        }
    }
    &-decor {
        &_top {
            width: 9999px;
            height: 600px;
            position: absolute;
            top: -1580px;
            left: -472px;
            background: url(#{$image-bg-url}bg-decor1.png);
            transform: rotate(-25deg)skew(-25deg);
            opacity: .2;
            z-index: 2;
        }
        &_bottom {
            width: 9999px;
            height: 600px;
            position: absolute;
            top: 3305px;
            left: -472px;
            background: url(#{$image-bg-url}bg-decor1.png);
            transform: rotate(25deg)skew(25deg)scaleX(-1);
            opacity: .2;
            z-index: -1;
        }
    }
}

.bg-decor_main {
    position: relative;
    z-index: 10;
}

.readspeaker {
    &__container {
        background: $color-3--1;
        margin-bottom: -2rem;
        padding-top: 1rem;
        position: relative;

        .rsbtn {
            padding-bottom: 0;
            z-index: 3;

            .rsbtn_tooltoggle {
                height: 12px;
            }

            .rsbtn_toolpanel {
                z-index: 3;
            }

            button {
                cursor: pointer;
            }
        }
    }

    &__wrapper {
        @extend .wrapper-main;
    }

    .home & {
        &__container {
            background-color: transparent;
            padding-top: 0;
            position: absolute;
            top: 6.5rem;
            //margin-top: -11.5rem;
            //width: 100%;
            right: 15rem;
            width: auto;
            z-index: 100;
        }

        &__wrapper {
            display: flex;
            justify-content: flex-end;
            //.rsbtn_play {
            //    float: right
            //}
        }

    }
}
