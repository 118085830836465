.column-bloc.bloc-contact {
    .bloc-contact__item {
        > p {
            text-align: center;
            margin-bottom: 32px !important;
        }
    }
    .column-bloc__item {
    	overflow: hidden;
    	.bloc-contact_col {
    		float: left;
    		width: 50%;
    		padding-right: 20px;
    	}
    	> .column-bloc__wrapper {
    		overflow: hidden;
    		padding-left: 20px;
    	}
    }
    .contact {
    	.button-2 {
    		margin-top: 24px;
    	}
    }
}
