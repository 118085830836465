.subscribe-form {
   $this: &;
   
   #{$this}_frame {
      padding:17px 15px 25px;
      flex-wrap:wrap;
      justify-content:center;
   }
      
   .radio, .checkbox {
      width:45%;
      margin:0 0 22px;
   }
      
   #{$this}_svg-icon {
      flex-shrink:0;
      margin:-21px 10px 0 0;
   }
      
   #{$this}_text {
      width:calc(100% - 75px);
      font-size:2rem;
      font-weight:$light;
      padding:0 0 0 6px;
      margin:0 0 20px;

      &:after {
         display:none;
      }

      strong {
         margin:0;
         font-size:2.2rem;
      }

      > div {
         margin-top:-6px;
      }
   }
      
   #{$this}_input {
      margin: 0 0 15px;
      width: 100%;
   }

   .home-section_subscribe & {
      padding: 20px;
      
      #{$this}_frame {
         justify-content: center;
         flex-direction: column;
      }
      #{$this}_text {
         strong {
            max-width: unset;
         }

         & > div {
            display: inline;
         }
      }

      &_input {
         margin: 25px 0;
      }
  }
}

.home-section_subscribe.home-section_col--lg {
   &::before,
   &::after {
      display: none;
   }
}
