.image-gallery {
    position: relative;
    margin: 0 10px;
    padding-top: 8px;
    .listItems {
        display: block;
        .item {
            white-space: normal;
            vertical-align: top;
            opacity: 1 !important;
            a {
                text-decoration: none;
                img {
                    position: static;
                    transform: translate(0, 0);
                    min-width: 0;
                    min-height: 0;
                    width: 100%;
                    display: block;
                    height: auto;
                }
            }
        }
    }
    .cycle-sentinel {
        padding-bottom: 70px!important;
    }
    .cycle-sentinel + .cycle-sentinel {
        display: none!important;
    }
    &-content {
        position: static;
        transform: translate(0, 0);
        background: none;
        padding: 19px 0 0;
        min-height: 154px;
        &-c {
            position: static;
            transform: translate(0, 0);
        }
        p {
            margin-bottom: 24px;
            font-size: 1.4rem;
        }
        span {
            font-size: 1.1rem;
        }
    }
    &-title {
        font-size: 2rem;
    }
    &-video {
        position: relative;
    }
    .prevNextCaroussel3 {
        display: block;
        position: static;
        width: auto;
        height: auto;
    }
    .prevCaroussel3,
    .nextCaroussel3 {
        position: absolute;
        z-index: 2;
        top: 0;
        left: -10px;
        margin: 0;
        width: 34px!important;
        button {
            width: 34px;
            height: 34px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: #fff;
            &:after {
                font-family: "icons-default";
                font-size: 1.53846em;
                position: absolute;
                top: 50%;
                right: inherit;
                bottom: inherit;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                color: $color-1--4;
                font-weight: 400;
                content: '\e01a';
                font-size: 1.6rem;
                line-height: 1;
            }
        }
    }
    .nextCaroussel3 {
        left: auto;
        right: -10px;
        button {
            &:after {
                content: '\e01b';
            }
        }
    }
}
