.moments-form {
    
    fieldset {
        text-align:left;
        
        > div {
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            max-width:510px;
            margin:0 auto;
            
            > div {
                width:46%;
            }
        }
    }
    
    label {
        display:inline-block;
        text-align:left;
        font-size:1.3rem;
        font-weight:$light;
        color:rgba($color-black,0.5);
        margin:5px 0;
    }
    
    .form__field-wrapper {
        margin:0 0 24px;
    }
    
    button {
        margin:0;
    }
    
    .moments-form_btn-box {
        display:inline-flex;
        align-items:center;
        justify-content:flex-end;
    }
}