.top-of-content__wrapper {
    &:before {
        left: 0;
        right: 0;
    }
}

.section-main__wrapper-2 {
    display: block;
}

.header {
    &-bar {
        position: static;
        transform: translate(0, 0);

        &:not(:only-child) {
            margin: 0;
        }

        &-inner {
            background: none;
            padding: 22px 101px 22px 20px;

            &--no-banner {
                margin-top: 0;
            }

            .language {
                position: absolute;
                top: 50%;
                right: 90px;
                transform: translateY(-50%);
            }
            &:before {
                display: none;
            }
        }
        .home & {
            display: none;
        }
    }
    .menu-main,
    .search-box {
        display: none;
    }
    &-top-bar {
        padding: 22px 101px 22px 20px;
        align-items: flex-start;
    }
}

.header-banner-show {
    display: flex;
    flex-wrap: wrap;

    .header-banner {
        width: 100%;
        order: 1;
    }

    .header-bar {
        order: 0;
        width: 100%;
        //margin: 0;
    }
}

.top-of-content.top-of-content--with-banner {
    .top-of-content__wrapper {
        padding-top: 20px;
    }
}

.top-of-content__wrapper {
    padding-top: 85px;
}

// @name Main section content
.section-main__content {
    width: 100%;

    &:only-child {
        max-width: 100%;
    }
}

.section-main__aside {
    max-width: 527px;
    margin: 0 auto;
    padding-left: 0;
    .title-3 {
        max-width: 428px;
        margin: 0 auto 30px;
        text-align: center;
        &:before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.footer {
    padding: 0 10px;
    .footer__wrapper-2 {
        justify-content: space-between;
        > * {
            vertical-align: top;
        }
    }
}

.page {
    position: relative;
    .header-bar-inner {
        .language {
            display: block;
        }
    }
    &-decor {
        &_top {
            height: 268px;
            top: -1936px;
            background-size: 20px 20px;
        }
        &_bottom {
            height: 268px;
            top: 2581px;
            background-size: 20px 20px;
        }
    }
}

.readspeaker {
    .home & {
        &__container {
            top: 2.5rem;
            //margin-top: -6rem;
        }

        &__wrapper {
            > div {
                margin-right: 8rem;
            }
        }
    }
}
