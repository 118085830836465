.report-form {
    padding:10px;
    &:before {
    }

    fieldset {
        padding:19px 20px 17px;
    }

    legend {
        width:15%;
        font-size:2.2rem;
        margin:6px 0 0;
    }

    label {
        width:26%;
        padding:0 0 0 34px;
        margin:-13px 0 -7px;
        font-size:1.7rem;
        &:before {
            left:10px;
            top:50%;
            background:url(#{$path-images}icons/arrow-right-white.svg) no-repeat;
            width:25px;
            height:27px;
            transform:translateY(-50%);
        }
    }

    select {
        height:40px;
        font-size:1.3rem;
        margin:0 10px 0 0;
        padding:0 22px 0 16px;
    }

    button {
        font:$bold 1.3rem/1 $typo-4;
        background:url(#{$image-bg-url}bg-button-report-form.png) repeat;
    }
}
