// @name site informations
.site-infos {
    padding-left: 54px;
    width: calc(100% - 582px);
    .site-infos__img-wrap {
        margin: 1.5rem 0;
    }
    .site-infos__wrapper {
        overflow: hidden;
        color: #fff;
        font-style: normal;
            margin-right: 27px;
        p {
            font-size: 1.4rem;
            font-weight: 300;
            font-family: $typo-4;
            line-height: 1.1;
            strong {
                font-size: 1.8rem;

            }
        }

        .phone {
            position: relative;
            margin-top: 12px;
            display: block;
            text-decoration: none;
            padding-left: 21px;
            color: #fff;
            font-size: 2rem;
            font-weight: 300;
            font-family: $typo-4;
            &:before {
                font-family: "icons-default";
                font-size: 1.8rem;
                color: $color-1--1;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                content: "\e02b";
                line-height: 1;
            }

            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
    p,
    ul {
        font-size: 1.3em;
        margin: em(.5, 1.3) 0 em(.8, 1.3);
        &:first-child {
            margin-top: 0;
        }
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    a {
        color: $color-black;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    &-wrap {
        display: flex;
    }
    &-button {
        padding-top: 3px;
        margin-right: 34px;
    }
}
