.ddm__content {
    .ddm__button {
        padding: 0.25em 2em 0.25em 1.5em;
    }
    h3 {
        &:after {
            right: -28px;
            left: -28px;
        }
    }
}
.ddm.ddm__content.ddm--active .ddm__sub-level {
    padding: 25px 28px;
}