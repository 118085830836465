.home_gallery-section {
    overflow: hidden;
    width: 100%;

    &_img-holder {
        overflow: hidden;
        background: #fff;
        img {
            opacity: .5;
            transition: .2s;
        }
    }

    &_container {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        max-width: 510px;
        line-height: 1.1;
        text-align: center;
        transform: translateX(-50%);
        z-index: 11;
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 4px;
            right: 5px;
            width: 15px;
            height: 55px;
            box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 0.5);
            transform: rotate(-9deg);
            z-index: 10;
        }
        &:before {
            right: auto;
            left: 5px;
            transform: rotate(9deg);
            box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.5);
        }
    }

    &_frame {
        position: relative;
        padding: 21px 25px 29px;
        z-index: 12;
        background: #fff;
    }

    &_block {
        position: relative;
        font-family: $typo-4;
        font-weight: 700;
        font-size: 4.2rem;

        a {
            color: $color-2--2;
            white-space: normal;
            &:hover,
            &:focus {
                color: $color-1--2;
                text-decoration: none;
            }
        }
    }

    &_title {
        position: relative;
        font-size: 1.4rem;
        font-weight: $bold;
        color: $color-1--2;
        text-transform: uppercase;
        margin: 0 0 6px;
        a {
            font-size: 1.4rem;
            font-weight: $bold;
            color: $color-1--2;
            text-transform: uppercase;
            margin: 0 0 6px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: -28px;
            background: url(#{$image-bg-url}bg-heading_title2.png) no-repeat;
            width: 60px;
            height: 16px;
            transform: translateX(-50%);
            z-index: 12;
        }
    }

    @media screen and (max-width: 767px) {
        .cycle-sentinel {
            display: none !important;
        }

        .cycle-carousel-wrap {
            position: relative !important;
        }
    }
}

.carrousel1 {
    position: relative;
    overflow: hidden;

    .listItems {
        overflow: visible !important;
        margin: 0 129px;

        .item {
            width: 100%;
            padding: 0 15px 103px;
            z-index: 10 !important;
            position: relative !important;
            &.cycle-slide-active {
                .home_gallery-section_img-holder {
                    img {
                        opacity: 1;
                    }
                }
            }

            img {
                display: block;
                max-width: 100%;
                height: auto;
                width: 100%;
            }
        }
    }
    .cycle-paused+.buttonStop {
        &:after {
            background: url(#{$path-images}carrousel1/play.png) no-repeat;
        }
    }
    .prevNextCarrousel1 {

        .prevCarrousel1,
        .nextCarrousel1 {
            position: absolute !important;
            left: 69px;
            top: 50%;
            width: 45px;
            height: 45px;
            margin-top: -45px;
            background-color: white;
            transform: translateY(-50%);
            z-index: 11;
            &:hover {
                opacity: 0.85;
            }

            button {
                display: block;
                width: 100%;
                height: 100%;
                text-indent: -9999px;
                overflow: hidden;
                cursor: pointer;
                @include default-icons-absolute-before('\e01a', 22px, $color-1--4, 50%, inherit, inherit, 50%);
                &:before {
                    text-indent: 0;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .nextCarrousel1 {
            left: auto;
            right: 69px;

            button {
                &:before {
                    content: '\e01b';
                }
            }
        }
    }

    .buttonStop {
        position: absolute;
        left: 50%;
        bottom: 2px;
        width: 7px;
        height: 8px;
        z-index: 12;
        margin: 0 0 0 21px;
        img {
            display: none;
        }
        &:after {
            content: "";
            background: url(#{$path-images}carrousel1/pause.png) no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
        }

        .txt {
            @extend .hidden;
        }

        img {
            vertical-align: top;
        }
    }

    .pagerCarrousel1 {
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 50%;
        margin: -12px 0 0 2px;
        z-index: 12;

        li {
            margin: 0 2px;
            &.cycle-pager-active {

                button {
                    background-color: $color-1--2;
                }
            }
        }

        button {
            display: block;
            width: 12px;
            height: 12px;
            background-color: $color-1--1;
            border-radius: 100%;
            &:hover,
            &:focus {
                background-color: $color-1--2;
            }

            img {
                display: none;
            }
        }
    }
}
