.quick-access-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.2rem;
    line-height: 1.2;

    li {
        display: flex;
        text-align: center;
        width: 33.33%;
        padding: 0 1px 1px 0;
    }

    a {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 14px 10px 18px;
        color: #384249;
        background-color: #e9eff3;
        &:hover,
        &:focus {
            background-color: #e1e3e4;
            text-decoration: none;
            .quick-access-ico-active {
                opacity: 1;
            }
            .quick-access-ico-default {
                opacity: 0;
            }
        }

        span {
            width: 100%;
        }
    }

}

.quick-access-ico-holder {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 0 5px 0;
}

.quick-access-ico {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 75%;
    &-active {
        opacity: 0;
    }
    &-default {
        opacity: 1;
    }

}
