// Galerie classique (avec vignettes) + Galerie album
.section-main__content {
  .classical-gallery {
  	width: auto !important;
  	max-width: none;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 82px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    position: relative;
    .carousel-galerie__thumb {
    	display: none;
    }
  }
  .slider-galerie {
  	margin: 0;
  	.infos-img {
  		display: block;
  		width: 100%;
  		margin: 0;
  		padding: 5px;
  		&__wrap {
  			width: 74%;
  			float: right;
  			position: relative;
  			&:after {
  				font-family: icons-default;
  				font-size: 12px;
  				line-height: 1;
  				position:absolute;
  				top:2px;
  				left:-15px;
  				font-style: normal;
  				color: $color-1--4;
  				content: '\e015';
  			}
  		}
  	}
  }
  .carousel-galerie {
		position: static;
		display: block;
	}
	.carousel-galerie__pager {
		position: static;
		width: auto;
		height: auto;
	}
	.carousel-galerie__prev,
	.carousel-galerie__next {
		position:absolute;
		z-index: 2;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		margin: 0;
		button {
			width: 34px;
			height: 34px;
			background: $color-1--2 !important;
			&:focus {
				outline-color: #fff;
			}
			&:before {
				color: #fff;
				font-weight: 400;
				content: '\e01a';
				font-size: 1.6rem;
			}
		}
	}
	.carousel-galerie__next {
		left: auto;
		right: 0;
		button {
			&:before {
				content: '\e01b';
			}
		}
	}
}
