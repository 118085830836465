.logo {
    width: 170px;
    img {
    	display: block;
    	width: 100%;
    	height: auto;
    }
    .home & {
    	width: 170px;
    }
}
