// @name Cross menu
.footer {
    .menu-cross {
        width: 205px;
        padding: 0;
        border: none;
        ul {
            padding-left: 36px;
            margin-bottom: 11px;
        }
        .button-connect {
            display: block;
        }
    }
}
