// @name Go so far
// @description Sections content below the content

.go-so-far__wrapper {
    flex-wrap: wrap;
    > * {
        flex-basis: 100%;
    }
}

.go-so-far {
	padding: 0;
	&_item {
		margin-right: 0;
	}
	.heading-title {
		width: 100%;
		display: block;
		h2 {
			padding-bottom: 34px;
			margin-bottom: 29px;
			&:before {
				right: auto;
				left: 50%;
				transform: translateX(-50%);
				width: 42px;
				height: 11px;
				background-size: cover;
			}
			text-align: center;
			font-size: 3rem;
			br {
				display: none;
			}
		}
	}
	&_block {
		display: block;
		padding: 0 20px !important;
		.go-so-far_item {
			width: 100%;
			margin: 0 0 15px;
		}
	}

}
