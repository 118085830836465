.web-gallery {
    position: relative;
    width: 100%;
    padding: 0 45px 20px 12px;
    z-index:7001;
    
    .listItems {
        display: flex;
        margin: 0 -17px;
        
        .item {
            padding: 0 17px;
            white-space: normal;
            vertical-align: top;
            a {
                display: block;
                text-decoration: none;
                &:hover,
                &:focus {
                    outline: none;
                    .web-gallery {
                        
                        &_title {
                            background:$color-3--1;
                        }
                        
                        &_img {
                            &:after {
                                opacity:1;
                            }
                        }
                    }
                }
            }
        }
    }
    
    &_img {
        position: relative;
        overflow: hidden;
        &:after {
            content:'';
            opacity:0;
            position:absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
            border:10px solid $color-1--1;
            z-index:1;
            transition:opacity 0.3s ease;
        }
        
        img {
            display: block;
            margin:0 auto;
            width:100%;
            max-width:none;
        }
    }
    
    &_title {
        padding:8px;
        font: $bold 1.4rem/1.2 $typo-4;
        color: $color-1--2;
        text-transform:uppercase;
        background:$color-white;
        transition:all 0.3s ease;
    }
    
    .cycle-carousel-wrap {
        .item {
            opacity: 1 !important;
        }
    }
    
    .prevNextCaroussel3 {
        
        .prevCaroussel3,
        .nextCaroussel3 {
            position: absolute !important;
            left: 0;
            top:50%;
            width: 45px;
            height: 45px;
            margin-top:-25px;
            background-color: $color-1--2;
            transform:translateY(-50%);
            z-index: 11;
            &:hover {
                opacity: 0.85;
                background:$color-white;
                
                button {
                    &:before {
                        color:$color-1--2;
                    }
                }
            }
            
            button {
                display:block;
                width:100%;
                height:100%;
                text-indent:-9999px;
                overflow:hidden;
                cursor:pointer;
                @include default-icons-absolute-before('\e01a', 22px, #fff, 50%, inherit, inherit, 50%);
                &:before {
                    text-indent:0;
                    transform:translate(-50%, -50%);
                }
            }
        }
        
        .nextCaroussel3 {
            left: auto;
            right: 35px;
            
            button {
                &:before {
                    content:'\e01b';
                }
            }
        }
    }
}