// @name Main menu
$menu-main__font-size: 1.2;
$menu-main__lvl1__a__font-size: 1.8;
.mobile-component {
    display: none;
}

.menu-main {
    display: flex;
    flex: auto;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    font-family: $typo-4;
    li a {
        &:focus {
            outline-offset: -1px;
        }

        &.is-active {
            text-decoration: underline;
        }
    }
    .menu-main__level-1 {
        display: flex;
        align-items: stretch;
        width: 100%;
        > li {
            flex: auto;
            & > .menu-main__sublink-icon {
                visibility: hidden;
                display: none;
            }
            &:first-child {
                > a {
                    &:after {
                        display: none;
                    }
                }
            }
            > a {
                .home & {
                    font-size: 2rem;
                }
                justify-content: center;
                font-size: #{$menu-main__lvl1__a__font-size}em;
                font-weight: 400;
                color: $color-white;
                padding: 10px 10px;
                transition: all ease .3s;
                text-decoration: none;
                display: flex;
                align-items: center;
                position: relative;
                z-index: 4;
                height: 70px;
                overflow: hidden;
                position: relative;
                white-space: nowrap;
                &:before {
                    display: inline-block;
                    vertical-align: middle;
                    font-family: icons-default;
                    font-size: 11px;
                    line-height: 1;
                    color: #fff;
                    margin: 1px 5px 0 0;
                    content: '\e014';
                }
                &:after {
                    position: absolute;
                    top: 0;
                    left: -20px;
                    bottom: 0;
                    width: 10px;
                    box-shadow: 10px 0 10px 0 rgba(0, 0, 0, 1);
                    transform: rotate(-9deg);
                    display: block;
                    content: '';
                }
                &:focus {
                    outline-color: #fff;
                }
            }
            &:hover > a,
            &:focus > a,
            &.active > a {
                background: $color-1--2;
                color: #fff;
                z-index: 6;
                &:after {
                    display: none;
                }
            }
            &.active > a {
                &:before {
                    content: '\e015';
                    color: $color-1--1;
                }
            }
        }
    }
    .menu-main__level-2 {
        background: $color-1--2;
        position: absolute;
        top: calc(100% - 10px);
        left: 10px;
        right: 10px;
        font-size: 2rem;
        border-bottom: 0;
        padding: 30px 27px 34px 50px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        &.show {
            opacity: 1;
            z-index: 1000;
            visibility: visible;
            .menu-main__closer {
                visibility: visible;
            }
        }
        .menu-main__subblock-level-2 {
            float: left;
            width: 200px;
            margin: 0 50px 0 0;
            .menu-main__sublink-level-2--preview-img {
                margin: 0;
                position: relative;
                display: block;
                &:after {
                    background: url(#{$image-bg-url}bg-decor-02.png) no-repeat;
                    width: 44px;
                    height: 12px;
                    position: absolute;
                    bottom: -6px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    content: '';
                }
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .menu-main__sublink-level-2--preview-text {
                padding-top: 22px;
                text-align: right;
                color: $color-white;
                h2 {
                    font-size: 1.4rem;
                    line-height: 1.25;
                    margin: 0 0 13px;
                    font-weight: 300;
                    font-style: italic;
                    a {
                        color: #fff;
                    }
                }
            }
        }
        .menu-main__subul-level-2 {
            & > li {
                position: relative;
                padding-bottom: 16px;
                &.active {
                    position: static;
                }
                &:last-child {
                    background: none;
                }
            }
            li.noSublevel {
                .menu-main__sublink-icon {
                    pointer-events: none;
                    &:after {
                        display: none;
                    }
                    &:before {
                        font-family: icons-default;
                        content: '\e017';
                        font-size: 16px;
                        line-height: 1;
                        color: #fff;
                        background: transparent;
                        width: auto;
                        height: auto;
                    }
                    &:hover,
                    &:focus {
                        background: transparent;
                        &:before {
                            background: transparent;
                        }
                    }
                }
                a {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .menu-main__subul--wrapper {
            display: flex;
            flex-wrap: wrap;
            min-height: 260px;
            padding-left: 41px;
            border-left: 1px solid #fff;
            position: relative;
            > ul {
                > li {
                    > a {
                        display: block;
                        color: #fff;
                        font-size: 2rem;
                        line-height: 1.2;
                        padding: 2px 0 2px 41px;
                        position: relative;
                        &:after {
                            width: 1px;
                            background: #fff;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 31px;
                            content: '';
                        }
                        &:hover,
                        &:focus {
                            color: $color-3--1;
                            text-decoration: none;
                            outline-color: #fff;
                        }
                        &.menu-main__sublink-level-2 {
                            text-align: left;
                            &:hover,
                            &:focus {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                    & > .menu-main__sublink-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 28px;
                        height: 28px;
                        overflow: hidden;
                        z-index: 2;
                        &:hover,
                        &:focus {
                            background: #fff;
                            &:after,
                            &:before {
                                background: #0f356c;
                            }
                        }
                        &:focus {
                            outline-color: #fff;
                        }
                        &:after,
                        &:before {
                            background: #fff;
                            width: 1px;
                            height: 11px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            content: '';
                        }
                        &:before {
                            width: 11px;
                            height: 1px;
                        }
                    }
                }
            }
        }
        .menu-main__closer {
            width: 34px;
            height: 34px;
            text-indent: -9999px;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            &:after,
            &:before {
                width: 2px;
                height: 17px;
                background: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                content: '';
            }
            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:focus {
                outline-color: #fff;
            }
        }
    }
    .menu-main__level-3 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        min-height: 100%;
        background: #fff;
        display: none;
        z-index: 10;
        padding: 2px;
        visibility: visible;
        &.show {
            display: block;
        }
        &-nav {
            overflow: hidden;
            display: flex;
            padding: 0 22px;
            ul {
                li {
                    padding-bottom: 16px;
                    &:last-child {
                        background: none;
                    }
                }
                a {
                    font-size: 1.8rem;
                    line-height: 1.2;
                    color: $color-1--3;
                    font-weight: 300;
                    position: relative;
                    display: block;
                    padding: 4px 0 4px 40px;
                    &:after {
                        position: absolute;
                        top: 50%;
                        left: 6px;
                        transform: translate(0, -50%);
                        font-family: icons-default;
                        font-size: 16px;
                        line-height: 1;
                        color: $color-1--3;
                        content: '\e08a';
                    }
                    &:before {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 28px;
                        width: 1px;
                        background: $color-1--2;
                        content: '';
                    }
                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                    &:focus {
                        outline-color: $color-1--3;
                    }
                }
            }
        }
        &-back {
            display: block;
            width: 100%;
            color: #fff;
            font-weight: 700;
            font-size: 2rem;
            line-height: 1.2;
            background: $color-1--2;
            padding: 8px 10px 8px 66px;
            position: relative;
            text-align: left;
            margin-bottom: 25px;
            &:after {
                position: absolute;
                top: 50%;
                left: 24px;
                font-family: icons-default;
                transform: translate(0, -50%);
                font-size: 13px;
                line-height: 1;
                color: #fff;
                font-weight: 400;
                content: '\e01a';
            }
            &:before {
                position: absolute;
                top: 8px;
                bottom: 8px;
                left: 50px;
                width: 1px;
                background: #fff;
                content: '';
            }
            &:focus {
                outline-color: #fff;
                outline-offset: -1px;
            }
        }
    }
}

.mobile-menu {
    display: none;
}
