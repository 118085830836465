.publication-list {
    padding:20px 20px 15px;
    
    &_item-box {
        width:100%;
        margin:0 0 30px;
        &:last-child {
            margin-bottom:0;
        }
    }
    
    &_item-box--md {
        width:100%;
        border-bottom:1px solid #282d33;
    }
    
    &_item {
        display:block;
        text-align:center;
        
        &_picture {
            width: 100%;
            margin: 0 0 11px;
            
            a {
                padding:11px 5px;
            }
        }
        
        &_text-box {
            display:block;
            height:auto;
            padding:0;
        }
        
        &_link {
            font-size:1.3rem;
            margin:0 0 6px;
        }
        
        &_title {
            font-size:1.6rem;
            margin:0 0 18px;
        }
        
        &_link-box {
            display:block;
            text-align:center;
            padding:0 0 26px;
        }
    }
}