.language {
	margin: 5px 10px 0 20px;
	.ddm__title {
		display: none;
	}
	&-list {
		display: flex;
		li {
			margin: 0 2px;
		}
		a {
			display: block;
			padding: 0 6px 10px;
			color: #606060;
			font-family: $typo-4;
			font-weight: 300;
			text-transform: uppercase;
			position: relative;
			font-size: 1.3rem;
			line-height: 1.2;
			text-decoration: none;
			&:after {
				height: 5px;
				width: 100%;
				background: #22a5df;
				position:absolute;
				bottom: 0;
				left:0;
				opacity: 0;
				content: '';
			}
			&:hover {
				color: $color-1--2;
				&:after {
					opacity: 1;
				}
			}
		}
		.active {
			a {
				color: $color-1--2;
				font-weight: 700;
				&:after {
					opacity: 1;
				}
			}
		}
	}
	&-selection {
		width: 195px;
		flex-shrink: 0;
		margin-right: -10px;
		&-title {
			font-family: $typo-5;
			color: #060606;
			font-size: 1.3rem;
			line-height: 1.2;
			margin: 0 0 5px;
			display: block;
		}
	}
}

/*
    Temporary styles
 */

.language, .language-selection {
    visibility: hidden;
}
