// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
    max-width: 118rem;
    margin: 0 auto;
    padding: 0 1rem;
    @extend .clear-fix;

    .home & {
        max-width: 122rem;
    }
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    position: relative;
    background: $color-3--1;
    &:before,
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        width: 999em;
        height: 100%;
        background: $color-3--1;
    }
    &:before {
        left: -9920px;
    }
    &:after {
        right: -9920px;
    } // @description Displayed in dark background on the sides.
    &.background-in-wrapper--dark {
        background: $color-3--3;
        margin: -6em 0 -2.5em !important;
        &:before,
        &:after {
            background: $color-3--3;
        }
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 35;
$column-bloc__padding-left: 8;
.column-bloc {
    width: #{$column-bloc__width}rem;
    padding-left: #{$column-bloc__padding-left}rem;
    margin-bottom: 71px;
    //&:first-of-type {
    //    margin-top: 17px;
    //}
    //&:last-child {
    //    margin-bottom: 0;
    //}

    .title-3 {
        position: relative;
        padding: 0 0 16px;
        margin: 0 0 30px;
        border: none;
        a {
            color: #727272;
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 15px;
            background: url(#{$image-bg-url}bg-title-3.png) repeat;
            z-index: 1;
        }
        &:before {
            content: '';
            position: absolute;
            left: 13px;
            bottom: -3px;
            background: url(#{$image-bg-url}bg-title-3-figure.png) no-repeat;
            width: 32px;
            height: 9px;
            z-index: 2;
        }
    }

    &_list {
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
        list-style: none;
        text-transform: uppercase;

        li {
            position: relative;
            padding: 8px 5px 8px 15px;
            border-bottom: 1px solid $color-3--2;
            @include default-icons-absolute-before('\e017', 1.4rem, $color-1--1, 9px, inherit, inherit, 0);
        }

        a {
            color: $color-black;
            &:hover,
            &:focus {
                color: $color-1--1;
                text-decoration: none;
            }
        }
    }
}

.section-main__aside {
    .colPos10:first-of-type {
        .column-bloc {
            margin-top: 17px;
        }
    }
    .colPos10:last-of-type {
        .column-bloc {
            margin-bottom: 0;
        }
    }
}

.column-bloc__wrapper {
    padding: 0;
}

.wrapper-1220 {
    max-width: 1240px!important;
}
