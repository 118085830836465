// @name Search box
// @description Search module
.search-box {
    padding: 0;
    position: relative;

    form {
        margin: 0;
    }

    .ddm__title {
        width: 70px;
        height: 70px;
        position: relative;
        button {
            background: none;
            width: 100%;
            height: 100%;
            svg {
                position:absolute;
                top:0;
                left:0;
                bottom: 0;
                right: 0;
                margin: auto;
                fill: #fff;
                width: 20px;
                height: 20px;
            }
        }
    }

    .ddm--active {
        .ddm__title {
            button {
                position: relative;
                svg {
                    display: none;
                }
                &:after,
                &:before {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    width: 20px;
                    height: 1px;
                    background: #fff;
                    content: '';
                    transition: all 0s linear !important;
                }
                &:before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }

    &_fields {
        display: flex;
        align-items: center;
        label {
            color: #384249;
            font-size: 1.6rem;
            line-height: 1.2;
            margin: 0 10px;
            font-weight: 300;
            white-space: nowrap;
        }
    }
    &__fields-input {
        width: 100%;
        position: relative;
        &:after {
            background: #c4c4c5;
            position:absolute;
            top:9px;
            bottom: 9px;
            right: 0;
            width: 1px;
            content: '';
        }
    }
    &__fields-wrapper {
        width: 408px;
        background: #f5f5f6;
        flex-shrink: 0;
        display: flex;
        button {
            width: 70px;
            height: 58px;
            position: relative;
            background: none !important;
            flex-shrink: 0;
            svg {
                position:absolute;
                top:0;
                left:0;
                bottom: 0;
                right: 0;
                margin: auto;
                fill: $color-1--4 !important;
                width: 20px;
                height: 20px;
            }
        }
        input {
            height: 58px;
            background: none;
            padding: 0 22px;
            color: #7a7a7b;
            font-size: 14px;
            font-weight: 300;
            font-style: italic;
            border: none;
            &::-webkit-input-placeholder {
                color: inherit;
            }

            &::-moz-placeholder {
                color: inherit;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: inherit;
            }
        }
    }


    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            top: 100%;
            right: 0;
            left: auto;
        }
    }
    @media (min-width: 1280px) {
        .home & {
            .ddm__title {
                display: none !important;
            }
            .ddm__sub-level {
                position: static !important;
                display: block !important;
            }
        }
    }
}

// @name Search list
.pertinence {
    font-size: 1.2em;
    span {
        font-size: em(1, 1.2);
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .pertinence span {
        font-size: 1.1em;
        font-weight: $bold;
    }
}

meter {
    -webkit-appearance: meter;
    -moz-appearance: meterbar;
    width: em(6, 1.2);
    height: em(1, 1.2);
    border: 1px solid darken($color-3--1, 20%);
    &::-webkit-meter-bar {
        background: darken($color-3--1, 20%);
        height: 100%;
        width: 100%;
    }
    &::-webkit-meter-optimum-value {
        height: 100%;
        background: $color-1--2;
    }
    &:-moz-meter-optimum {
        background: darken($color-3--1, 20%);
        border: none;
    }
    &:-moz-meter-optimum::-moz-meter-bar {
        background: $color-1--2;
    }
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background: antiquewhite;
    }
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background: steelblue;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, meter {
        display: none;
    }
}
