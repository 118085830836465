.all-dates__listitems {
	margin: 0;
}
.all-dates__item {
	width: 48%;
	&:last-of-type {
		justify-content: center;
	}
	&:before {
		left: 7rem;
	}
   .hour-place {
   	flex: auto;
   }
   .date-1 {
   	padding-right: 0;

   }
}
