// @name Subtitle or category
$category__font-size: 1.4;
.subtitle,
.category {
    font-family: $typo-4;
    font-weight: $bold;
    font-size: #{$category__font-size}rem;
    text-transform: uppercase;
    color: $color-1--2;
}

// @name caption
// @description Default caption
.caption {
    font-size: 1.3em;
    font-weight: $light;
    font-style: italic;
    font-family: $typo-4;
    color: $color-2--2;
}

// @name Date
// @description Default date
.date {
    $date__font-size: 1.2;
    font-size: #{$date__font-size}em;
    font-weight: $normal;
    font-family: $typo-2;
    color: $color-3--3;
    margin: em(0.6, $date__font-size) 0;
    &.date--big {}
}

// @name Date 1
// @description Date in event
.date-1 {
    font-family: $typo-4;
    color: $color-white;
    text-align: center;
    margin: 0 10px 0 0;
    width: 80px;
    margin-bottom: 2em;

    span:first-child+time {
        margin-left: 0;
    }

    time {
        display: block;
        text-align: center;
        width: 60px;
        height: 60px;
        padding: 10px 0 0;
        background-color: $color-1--2;
        &+span+time {
            background-color: $color-1--3;
            @include default-icons-absolute-before('\e017', 1.2em, $color-white, -8px, inherit, inherit, 50%);
            &:before {
                transform: translateX(-50%) rotate(90deg);
            }
        }
    }
    .ghost {
        width: 0 !important;
        height: 0 !important;
    }
    .day,
    .month {
        display: block;
        line-height: 1;
    }
    .day {
        font-size: 2.8rem;
        font-weight: $bold;
    }
    .month {
        font-size: 1.2rem;
        font-weight: $light;
        text-transform: uppercase;
    }
    &-nm {
        span:first-child+time {
            margin-top: 0;
        }
    }
}

// @name Date 2
// @description Date in job
$date-2__font-size: 1.4;
$date-2--big__font-size: 2;
.date-2 {
    font-size: #{$date-2__font-size}em;
    text-transform: uppercase;
    color: $color-1--2;
    margin: 0 0 em(.8, $date-2__font-size);
    time {
        font-weight: $bold;
    }
    &.date-2--big {
        font-size: #{$date-2--big__font-size}em;
        margin: em(.5, $date-2--big__font-size) 0 em(.8, $date-2--big__font-size);
    }
}

// @name Date 3
// @description Date in public market
.date-3 {
    $date-3__font-size: 1.2;
    @include default-icons-absolute-before('\e05b', em(1.2, 1.2), $color-1--2, em(1.3, 1.2), inherit, inherit, 0);
    font-family: $typo-2;
    font-size: #{$date-3__font-size}em;
    font-weight: $bold;
    text-transform: uppercase;
    padding-top: em(1.2, $date-3__font-size);
    padding-left: em(1.8, $date-3__font-size);
    margin-bottom: em(1.2, $date-3__font-size);
    border-top: 1px solid darken($color-3--1, 20%);
    time {
        font-weight: $light;
    }
    &.date-3--big {
        $date-3--big__font-size: 1.6;
        font-size: #{$date-3--big__font-size}em;
        padding-top: em(1.2, $date-3--big__font-size);
        padding-left: em(2, $date-3--big__font-size);
        margin-bottom: em(1.2, $date-3--big__font-size);
        margin-top: em(1.2, $date-3--big__font-size);
        &:before {
            font-size: em(1.6, $date-3--big__font-size);
            top: em(1.3, $date-3--big__font-size);
        }
        br {
            display: none;
        }
    }
}

// @name Date 4
// @description Date in publications bloc
.date-4 {
    $date-4__font-size: 1.5;
    font-size: #{$date-4__font-size}em;
    text-transform: uppercase;
    font-weight: $light;
    margin: 0 0 em(2, $date-4__font-size);
    time {
        &+span+time {
            @include default-icons-before('\e017', 0 5px 0 0, em(1.4, $date-4__font-size), $color-black, em(-.1, 1.4));
        }
    }
}

// @name Media
// Media in publications, public market, albums
.media {
    border-top: 1px solid darken($color-3--1, 20%);
    color: $color-3--3;
    text-transform: uppercase;
    font-size: 1.2em;
    padding-top: em(1.2, 1.2);
}

// @name Hour and place wrapper
.hour-place {
    text-align: center;
}

// @name Hour
// @description Default hour
.hour {
    $hour__font-size: 1.6;
    font-family: $typo-4;
    display: block;
    font-weight: $light;
    font-size: #{$hour__font-size}rem;
    color: #384249;
    margin-bottom: em(0.5, $hour__font-size);
}

// @name Place
// @description Default place
.place {
    font-size: 1.4rem;
    font-family: $typo-4;
    font-weight: $light;
    color: #384249;
}

// @name Teaser 1
// @description Teaser in list view
.teaser-1 {
    $teaser-1__font-size: 1.3;
    font-size: #{$teaser-1__font-size}em;
    margin: em(.8, $teaser-1__font-size) 0;
}

// @name Teaser 2
// @description Teaser in heading
$teaser-2__font-size: 1.8;
.teaser-2 {
    font-size: #{$teaser-2__font-size}rem;
    line-height: 1.445;
    color: $color-black;
    margin: em(1.2, $teaser-2__font-size) 0 em(.5, $teaser-2__font-size);
    font-weight: $light;
}

// @name Teaser 3
// @description Teaser in bloc of right column
.teaser-3 {
    $teaser-3__font-size: 1.6;
    font-size: #{$teaser-3__font-size}em;
    font-weight: $normal;
    margin: em(.8, $teaser-3__font-size) 0;
}

// @name status
// @description Status for public market
.status {
    $status__font-size: 1.2;
    font-size: #{$status__font-size}em;
    background: $color-3--1;
    padding: em(.5, $status__font-size);
    font-family: $typo-2;
    font-weight: $light;
    text-transform: uppercase;
    margin: 0 0 em(.8, $status__font-size);
    @include default-icons-before('\e057', 0 5px 0 0, em(1, $status__font-size), $color-3--3, 0);
    &.status--new {
        background: $color-1--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
    &.status--in-progress {
        background: $color-2--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
}

// @name Message
// @state error - Display error message
$error__font-size: 1.3;
.message {
    position: relative;
    background: $color-3--1;
    font-size: #{$error__font-size}em;
    font-weight: $light;
    font-family: $typo-4;
    padding: 3.2rem 3rem 3rem 7.6rem;
    &:before {
        content: attr(aria-label);
        display: block;
        color: $color-black;
        font-size: 1.8rem;
        font-weight: $bold;
        margin-bottom: em(2, 2);
    }
    &:after {
        position: absolute;
        top: 31px;
        left: 39px;
        font-family: icons-default;
        font-size: 25px;
        line-height: 1;
        color: #ff000c;
        content: '\e07f';
    }
    >li {
        padding-left: em(1.5, $error__font-size);
        @include default-icons-absolute-before('\e027', em(1.2, $error__font-size), $color-black, em(.4, 1.2), inherit, inherit, 0);
        margin: em(.5, $error__font-size) 0;
    }
    &.message--error {
        background: #fcdcde;
        &:before {
            color: #ff000d;
        }
    }
    &.message--succes {
        background: lighten($color-green, 65%);
        &:before {
            color: $color-green;
        }
    }
    &.message--no-margin {
        &:before {
            margin: 0;
        }
    }
}

// @name No result
.no-result {
    font-size: 1.5em;
    font-weight: $light;
    text-align: center;
}

.heading-title {
    display: inline-block;
    min-width: 237px;
    text-align: left;
    overflow: hidden;
    h2 {
        position: relative;
        text-align: right;
        font-size: 4.5rem;
        font-weight: $light;
        font-family: $typo-4;
        color: $color-2--2;
        padding-bottom: 30px;
        line-height: 1;
        &:after,
        &:before {
            position: absolute;
            content: '';
            height: 16px;
        }
        &:after {
            background: url(#{$image-bg-url}bg-heading_title.png);
            left: -9999px;
            bottom: 0;
            right: -9999px;
        }
        &:before {
            background: url(#{$image-bg-url}bg-heading_title2.png) no-repeat;
            bottom: 7px;
            right: 27px;
            width: 60px;
            z-index: 1;
        }
    }
}

.heading__wrapper-date {
    .date-1 {
        span:first-child+time:last-of-type {
            margin-top: 0;
        }
    }
}
