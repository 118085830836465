//@name Page content
// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;
// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
// content in click and roll
.rte.ddm__sub-level > *:first-child, // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 4;
.rte h2,
.rte .h2,
.h2 {
    font-family: $typo-4;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $light;
    color: $color-2--2;
    margin: em(6, $content-h2__font-size) 0 18px;
    a {
        color: $color-2--1;
        &:hover,
        &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.rte h3,
.rte .h3,
.h3 {
    font-family: $typo-4;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $light;
    color: $color-1--5;
    text-transform: uppercase;
    margin: 42px 0 24px;
    padding-bottom: 10px;
    position: relative;
    &:after {
        position: absolute;
        content: '';
        bottom: -3px;
        left: 0;
        right: 0;
        height: 5px;
        background: url(#{$image-bg-url}bg-row.png);
    }
    a {
        color: $color-1--3;
        &:hover,
        &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2;
.rte h4,
.rte .h4,
.h4 {
    font-family: $typo-4;
    font-size: #{$content-h4__font-size}em;
    line-height: 1.2;
    color: $color-1--5;
    font-weight: $bold;
    margin: 40px 0 12px;
    a {
        color: $color-1--3;
        &:hover,
        &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.8;
.rte h5,
.rte .h5,
.h5 {
    font-family: $typo-4;
    font-size: #{$content-h5__font-size}em;
    line-height: 1.2;
    color: $color-2--2;
    font-weight: $normal;
    margin: 30px 0 15px;
    a {
        color: $color-3--3;
        &:hover,
        &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6,
.rte .h6,
.h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover,
        &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.4;
.rte {
    // @name Link
    a {
        color: $color-1--5;
        text-decoration: underline;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e086', 0 0 0 5px, 8px, inherit, middle);
    }
    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $light;
        color: $color-2--2;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
        font-family: $typo-4;
    }
    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }
    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        position: relative;
        $encadre__font-size: 1.6;
        padding: 21px 39px 25px;
        font-size: #{$encadre__font-size}em;
        background: url(#{$image-bg-url}bg-row.png) repeat;
        color: $color-white;
        z-index: 1;
        margin: 18px 0 18px;
        &:after {
            position: absolute;
            content: '';
            background: $color-1--5;
            left: 8px;
            right: 8px;
            top: 8px;
            bottom: 8px;
            z-index: -1;
        }
        a {
            color: #fff;
        }
    }
    // @name Blockquote
    $content-blockquote__font-size: 1.8;
    $content-blockquote-icon-1__font-size: 3.2;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        @include default-icons-absolute-before('\e06f', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), #99dcf7, 0, inherit, inherit, em(-6, $content-blockquote-icon-1__font-size));
        &:before {
            font-style: normal;
            font-size: 3.3rem;
            left: -60px;
            top: 1px;
        }
        padding: em(0.5, $content-blockquote__font-size) 0 em(0.5, $content-blockquote__font-size) em(2, $content-blockquote__font-size);
        font-weight: $light;
        // font-style: italic;
        margin: em(2.5, $content-blockquote__font-size) 0 em(2.5, $content-blockquote__font-size) em(6, $content-blockquote__font-size);
        color: $color-2--2;
        p {
            font-size: 1em;
            font-weight: $light;
            position: relative;
            padding-bottom: 6px;
            &:before {
                content: '';
                position: absolute;
                left: -30px;
                top: -8px;
                bottom: 0;
                width: 3px;
                background: url(#{$image-bg-url}bg-row.png);
            }
        }
    }
    .audio {
        audio {
            width: 100%;
            margin-bottom: 20px;
        }
        &-title {
            font-size: 1.2rem;
            color: $color-2--2;
            font-family: $typo-4;
        }
    }
    // @name Unordered list
    ul,
    .show-hide__content ul {
        margin: 1rem 0 1.5rem 2rem;
        list-style: none;
        font-size: 1.5rem;
        color: $color-2--2;
        padding: 0;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            @include default-icons-absolute-before('\e017', 1.6rem, $color-1--1, -.1rem, inherit, inherit, 3px);
            padding-left: 2rem;
            padding-bottom: 13px;
            > ul:first-child,
            > a + ul {
                // for specificity selector
                margin: 4px 0 -0.3rem 1.3rem;
                list-style: none;
                padding: .1rem 0 .1rem 0;
                > li {
                    @include default-icons-absolute-before('\e027', 1.4rem, $color-1--5, 0, inherit, inherit, -3px);
                    padding-left: 1.5rem;
                    padding-bottom: 3px;
                }
            }
            > ol:first-child,
            > a + ol {
                // for specificity selector
                margin: .3rem 0 0rem 1rem;
                padding: .1rem 0 .3rem 0;
                counter-reset: num;
                list-style: none;
                > li {
                    padding: 0 0 4px;
                    &:before {
                        content: counter(num) ".";
                        counter-increment: num;
                        color: $color-1--5;
                        padding-right: 6px;
                    }
                }
            }
        }
    }
    // @name Ordered list
    ol,
    .show-hide__content ol {
        margin: 1.3rem 0 1.5rem 0.7rem;
        padding: 0 0 0 1.8rem;
        list-style: none;
        counter-reset: num;
        font-size: 1.5rem;
        color: $color-2--2;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding: 0 0 13px;
            &:before {
                padding-right: 6px;
                content: counter(num) ".";
                counter-increment: num;
                color: $color-1--5;
                font-weight: 700;
            }
            > ol:first-child,
            > a + ol {
                // for specificity selector
                margin: 3px 0 -.5rem 2.7rem;
                padding: .1rem 0 .1rem 0;
                > li {
                    padding: 0 0 5px;
                    &:before {
                        font-weight: 300;
                    }
                }
            }
            > ul:first-child,
            > a + ul {
                // for specificity selector
                margin: 0 0 1rem;
                list-style: none;
                padding: .1rem 0 .3rem 0;
                > li {
                    padding-left: 3rem;
                    padding-bottom: 7px;
                    @include default-icons-absolute-before('\e023', .25rem, $color-black, .25rem, inherit, inherit, 0);
                }
            }
        }
    }
    ul.co-home-menu {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        > li {
            flex: 1 1 45%;
            padding-left: 0;
            h3 {
                img {
                    vertical-align: middle;
                }
                a {
                    font-size: 2.6rem;
                    text-decoration: none;
                }
            }
            &:before {
                content: none;
            }
            &:nth-child(odd) {
                margin-right: 5%;
            }
        }
    }
    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol,
            > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }
    // @name Table
    .table-wrapper {
        margin: 4em 0 68px;
    }
    .table-wrapper-inner {
        max-width: #{$content__max-width}em;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.3;
            text-align: right;
            font-weight: $normal;
            font-size: #{$table__caption_font-size}em;
            color: $color-2--2;
            margin-bottom: 10px;
            font-family: $typo-4;
            text-transform: uppercase;
        }
        th,
        td {
            padding: 13px 26px 12px 40px;
            border-bottom: 1px solid $color-2--2;
            border-color: $color-2--2;
        }
        th {
            font-size: 1.4em;
            text-transform: uppercase;
            font-weight: $bold;
            color: $color-1--5;
            text-align: left;
            font-family: $typo-4;
        }
        thead th {
            position: relative;
            font-size: 1.4em;
            background: none;;
            font-weight: $bold;
            color: $color-1--5;
            font-family: $typo-4;
            background: url(#{$image-bg-url}bg-row.png) left bottom repeat-x;
            border: none;
            &:after {
                position: absolute;
                content: '';
                bottom: -3px;
                left: 0;
                height: 5px;
                width: 100%;

            }
        }
        tbody th {
            text-align: right;
            padding-right: 1em;
            padding-left: 40px;
        }
        tbody tr {
            &:last-child {
                td,
                th {
                    border: none;
                }
            }
        }
        td {
            font-size: 1.4em;
            color: $color-2--2;
            background: none;
            font-weight: $light;
            font-family: $typo-4;
            border-bottom: 1px solid $color-2--2;
        }
    }
    // @name column 2
    .column--2,
    .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
    .ce-intext {
        &.ce-left {
            .ce-gallery {
                width: 280px;
                margin-top: 6px;
            }
        }
        p {
            overflow: hidden;
        }
    }
    .ce-gallery {
        figcaption {
            padding-top: 6px;
        }
    }
}

.spFilDAriane {
    display: none;
}
