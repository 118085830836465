.hero-gallery {
    margin: 0 -6px;
    padding-top: 0px;
    padding-bottom: 28px;
    .carrousel4 {
        height: 275px;
        width: 730px;
        margin: 0 auto;
        .buttonStopHero {
            left: 185px;
            bottom: 11px;
        }
    }
    .prevNextcarrousel4 {
        display: none;
    }
    .listItems {
        width: calc(100% - 238px);
        .item {
            height: 100%;
            z-index: 1!important;
            width: 100%;
        }
    }
    &-pagination {
        width: 238px;
        &-text {
            a {
                p {
                    font-size: 1.8rem;
                }
            }
        }
        li {
            padding: 16px 14px 10px;
            &.cycle-pager-active {
                &:after {
                    border-top: 17px solid transparent;
                    border-left: 13px solid #384249;
                    border-bottom: 17px solid transparent;
                    top: 47%;
                    right: -13px;
                }
            }
        }
    }
}
