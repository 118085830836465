// @name field-group
.form__field-group {
    flex-wrap: wrap;
}

// @name before-field
// @description Informatio before field
.form__attached-field {
    flex: 1 0 100%;
}

form {
	.form__field-datewrapper {
		width: 177px;
	}
}
