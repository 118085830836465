.column-bloc.bloc-news {
	.bloc-news {
		&__item {
			display: block;
		}
		&__picture {
			width: 100%;
			margin: 0 0 15px;
		}
		&__wrapper {
			width: 100%;
			padding: 0 10px;
		}
		&__title {
			margin: 0 0 17px;
		}
	}
	.link-view {
		margin: 0 -10px;
		padding-top: 9px;
		padding-left: 10px;
		padding-right: 10px;
	}
}