@media(min-width: 768px) and (max-width: 1279px) {
	.column-bloc.bloc-event {
		.bloc-event {
			&__item {
				display: flex;
			}
			&__picture {
				width: 50%;
			} 
			&__wrapper {
				width: 50%; 
				padding-left: 76px;
				position: relative;
				padding-bottom: 0;
				.link-view {
					position: static;
					margin: 22px 0 0 0 !important;
				}
			}
			&__text-holder {
				overflow: hidden;
			}
		}
		.date-1 { 
			position: absolute;
			left: -61px;
			width: auto;
			text-align: left;
			span:first-child + time {
				margin-top: 0;
				margin-right: -2px;
			}
			time {
				display: inline-block;
				vertical-align: top;
				& + span + time::before {
					top: 50%;
					left: -8px;
					transform: translateY(-50%);
				}
			}
		}
	}
}