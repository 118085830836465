.column-bloc.bloc-contact {
    .bloc-contact__item {
        > p {
            text-align: left;
        }
    }
    .column-bloc__item {
    	.bloc-contact_col {
    		float: none;
    		width: 100%;
    		padding-right: 0;
    	}
    	> .column-bloc__wrapper {
    		padding-left: 0;
    	}
    }
    .list-infos__item {
		font-size: 1.4rem;
    }
}
