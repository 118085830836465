// @name Go so far
// @description Sections content below the content
.go-so-far {
    background: url(#{$image-bg-url}bg_go-so-far.png);
    padding: 0 0 88px;
    .section-main__wrapper {
        padding-top: 56px;
    }
    &_title {
        position: relative;
        font-size: 1.8rem;
        padding-bottom: 10px;
        margin-bottom: 15px;
        color: $color-1--2;
        font-weight: $medium;
        font-family: $typo-4;
        text-transform: uppercase;
        a {
            color: $color-1--2;
        }
        &:after {
            position: absolute;
            content: '';
            background: url(#{$image-bg-url}bg-heading_title2.png) no-repeat;
            background-size: cover;
            left: 0;
            bottom: 0;
            width: 27px;
            height: 7px;
        }
    }
    &_block {
        display: flex;
        width: 100%;
        .go-so-far_item {
            width: 255px;
            margin-right: 11%;
        }
    }
}

.go-so-far__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    >* {
        margin: 0 5% 0 0;
    }
}

.go-so-far_item {
    .heading-title {
        min-width: 217px;
        h2 {
            font-size: 4.4rem;
            line-height: 0.8;
            padding-bottom: 40px;
        }
    }
    ul {
        li {
            font-size: 1.5rem;
            padding-bottom: 9px;
            a {
                display: block;
                position: relative;
                color: $color-2--2;
                padding-left: 15px;
                font-family: $typo-4;
                font-weight: $light;
                &:before {
                    position: absolute;
                    font-family: "icons-default";
                    content: '\e01b';
                    font-size: 1.2rem;
                    color: $color-1--1;
                    left: 0;
                    top: 3px;
                }
            }
        }
    }
}
