// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications__picture {
    display: block;
    float: left;
    img {
        margin-right: 2em;
    }
}

.bloc-publications__wrapper {
    overflow: hidden;
}

.bloc-publications__title {
    $bloc-publications__title__font-size: 2.4;
    font-size: #{$bloc-publications__title__font-size}em;
    font-family: $typo-3;
    font-weight: $bold;
    margin: 0 0 em(.8, $bloc-publications__title__font-size);
    color: $color-black;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-1--2;
        }
    }
}

.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}
