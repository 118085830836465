.filters__wrapper {
    display: block;

}
.filters__button-wrapper {
    display: block;
    text-align: center;
}
.filters__button-wrapper {
    padding-left: 0;
    padding-top: 0;
}
.filters__fields  {
    display: block;
}
.filters__fields-wrapper {
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.filters__field-wrapper {
    margin: 0 0 25px !important;
}