.subscribe-form {
    $this: &;
    
    margin:0;
    font-size:1.6rem;
    padding:10px;
    color:$color-white;
    background:url(#{$image-bg-url}bg-decor-form.png) repeat;

    fieldset {
        margin:0;
    }

    #{$this}_frame {
        display:flex;
        align-items:center;
        justify-content:space-between;
        width:100%;
        padding:15px 22px 13px 33px;
        background-color: #384249;
    }

    #{$this}_svg-icon {
        width: 61px;
        height: 50px;
        margin:-3px 0 0;
        fill: $color-1--1;
    }

    #{$this}_text {
        position:relative;
        width: 39%;
        margin:0 3% 0 0;
        padding:0 25px 0 33px;
        font-size:2rem;
        
        &:after {
            content:'';
            position:absolute;
            right:0;
            top:50%;
            background:url(#{$path-images}icons/arrow-right-white.svg) no-repeat;
            width:25px;
            height:27px;
            transform:translateY(-50%);
        }

        strong {
            display:inline-block;
            vertical-align:top;
            margin:-3px 0 0 -23px;
            font-size:2.2rem;
            font-weight:$bold;
        }

        > div {
            margin-top:-7px;
        }
    }

    #{$this}_input {
        margin-right: 4%;
        width: 35%;

        input {
            font-size: 1.6rem;
        }
    }

    .radio,
    .checkbox {
        margin:0;
        width:17%;
    }

    input[type=checkbox] {
        &:before {
            border:none;
        }
        &:checked + label:after,
        &:checked:disabled + label:after {
            border: 3px solid $color-1--1;
            border-top: none;
            border-right: none;
        }
        // Here you can change svg image
        &:checked:disabled + label:after {
            border-color: $color-3--2;
        }
    }

    label {
        font-size:1.6rem;
        line-height:1.1;
        font-weight:$light;
        color:inherit;
    }

    button,
    a {
        width:104px;
        height:40px;
        padding:0 5px 0 25px;
        color:$color-white;
        background:url(#{$image-bg-url}bg-button-subscribe-form.png);
        line-height: 40px;
        &:hover,
        &:focus {
            color:$color-white;
            background:url(#{$image-bg-url}bg-button-subscribe-form.png);
            opacity:.95;
        }
        &:before {
            left:14px;
        }
    }
    a {
        padding:0 5px 0 30px;
        &:before {
            content: "\e057";
        }
    }

    .home-section_subscribe & {
        background: #384249;
        margin-top: 20px;
        padding: 30px 200px 30px 270px;
        font-family: Ubuntu, sans-serif;
        
        @media (max-width: 1281px) and (min-width: 1219px) {
            padding: 30px 150px 30px 220px;
        }

        #{$this}_frame {
            background: transparent;
            padding: 7px 0 6px;
            position: relative;
            z-index: 1;
        }

        #{$this}_text {
            font-size: 26px;
            font-weight: $bold;
            line-height: 30px;
            margin-right: 27px;
            padding-right: 0;
            padding-left: 25px;
            width: auto;

            @media (max-width: 1281px) and (min-width: 1219px) {
                font-size: 24px;
            }
            
            &::after {
                display: none;
            }
            
            strong {
                font-size: inherit;
                line-height: inherit;
                margin: 0;
                max-width: 300px;
            }

            > div {
                margin: 0;
            }
        }

        &_input {
            margin-right: 10px;
            width: 300px;

            input {
                background-color: transparent;
                border-color: #34A4E8;
                border-style: solid;
                border-width: 0 0 1px 0;
                color: $color-white;
                font-size: 17px;
                padding-left: 0;

                &::-webkit-input-placeholder {
                    color: $color-white !important;
                    font-size: 17px;
                    font-style: italic;
                }
            }
        }

        button,
        a {
            background-color: #34A4E8;
            color: $color-black;
            font-family: inherit;
            font-size: 13px;
            font-weight: $bold;
            letter-spacing: 1.2px;
            padding: 0 30px 0 49px;
            width: 154px;
            
            &::before {
                content: '';
                background-image: url(../Images/icons/check.svg);
                background-repeat: no-repeat;
                background-size: 18px 23px;
                height: 24px;
                left: 22px;
                top: calc(50% + 1px);
                width: 24px;
            }

            &:hover {
                background-color: #ff5758;
            }
        }
    }
}

.home-section_subscribe.home-section_col--lg {
    width: calc(100% + 80px);
    margin: 0 -40px;
    position: relative;
    padding-right: 0;
    
    @media (max-width: 1281px) and (min-width: 1219px) {
        width: 100%;
        margin: 0;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        height: 133px;
        position: absolute;
        top: 0;
    }
    
    &::after {
        background: url('../Images/rond-bleu.svg') center no-repeat;
        right: 0;
        width: 170px;
    }
    
    &::before {
        background: url('../Images/rond-rouge.svg') center no-repeat;
        left: 0;
        width: 270px;
    }
}
