// @name bloc event
// @name Bloc event in column
.bloc-event {

    img {
        max-width: 100%;
        height: auto;
    }

    .bloc-event__wrapper {
        display: flex; //position:relative;
        color: #384249;
        padding: 15px 15px 36px 21px;
        margin: 0 0 10px;
    }

    .date-1 {
        time {
            //background: $color-1--2;
            position: relative;
        }
    }

    .bloc-event__text-holder {
        width: 100%;
        padding-bottom: 20px;
        .category {
            margin: 0 0 5px;
        }
    }

    .bloc-event__title {
        $bloc-event__title__font-size: 1.8;
        position: relative;
        font:$light #{$bloc-event__title__font-size}rem/1.2 $typo-4;
        margin: 0 0 9px;
        padding: 0 0 18px;
        color: #384249;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            background: url(#{$image-bg-url}bg-title-3-figure.png) no-repeat;
            width: 32px;
            height: 9px;
            opacity: 0.3;
            z-index: 1;
        }

        a {
            color: #384249;
            &:hover,
            &:focus {
                color: $color-1--1;
                text-decoration: none;
            }
        }
    }

    .hour-place {
        text-align: left;
        line-height: 1.1;
    }

    &__item {
        .link-view {
            padding-top: 15px;
            border-top: 1px solid $color-3--2;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 11;
        }

        .link-bloc:hover {
            text-decoration: none;

            .category {
                color: $color-1--1;
            }
        }
    }

    picture + .bloc-event__wrapper,
    img + .bloc-event__wrapper {
        .date-1 {

            span:first-child + time {
                margin-top: -7.5rem;
            }

            span:first-child + time:last-of-type {
                margin-top: -45px;
            }

        }
    }
}
