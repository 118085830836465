.go-so-far {
	&__wrapper {
		> * {
			margin: 0 8% 0 0;
		}
	}
	.section-main__wrapper {
		padding-top: 32px;
	}
	&_block {
		margin-right: 0 !important;
		.go-so-far_item {
			width: 50%;
		}
	}
	&_title {

	}
}
