.site-infos {
    padding: 0;
    &-wrap {
        align-items: center;
    }
    .site-infos__wrapper {
        p {
            text-align: center;
        }
        .phone {
            padding-left: 0;
            &:before {
                position: static;
                vertical-align: -10px;
                display: inline-block;
            }
        }
    }
    .site-infos__img-wrap {
        margin-top: 0;
        margin-bottom: 4.8rem;
    }
}
