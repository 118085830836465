// @name Pager list
.pager {
    li {
        &.pager__prev,
        &.pager__next {
            margin-top: -1px;
            span,
            a {
                min-width: 0;
                display: inline-block;
                text-indent: -9999px;
                width: 40px;
                height: 40px;
                white-space: nowrap;
                position: relative;
                padding: 4px;
                &:before,
                &:after {
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .pager-single .pager-single__back-to-list {
        a {
            max-width: 190px;
            
        }
    }
}
