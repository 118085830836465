// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    margin: 0 -1em;
    padding: 0 1em;
    &:before, &:after {
        width: 0;
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside

