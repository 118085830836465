.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    position: relative;
}

.menu-main-1 {
    @extend .wrapper-main;
}

.menu-main-1__item {
    > .menu-main-1__header {
        a {
            display: block;
        }
    }
    > .menu-main-1__sublevel {

    }
    &.menu-main-1__item--current {
        text-decoration: underline;
    }
}

.menu-main-1__closing-button {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 30px;
    height: 30px;
    background: transparent;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        content: "X";
        font-size: 1.3em;
        font-weight: $bold;
        text-indent: 0;
        line-height: 0;
        display: block;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        > .menu-main-1__header {
            display: none;
        }
        > .menu-main-1__sublevel {
            display: block;
            > .menu-main-1__closing-button {
                display: none;
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    > li {
        display: inline-block;
        vertical-align: middle;
        > .menu-main-1__header {
            > a {
                font-size: 2em;
                color: $color-black;
                text-transform: uppercase;
                padding: em(1, 2) em(2, 2);
                border: 1px solid $color-1--2;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            .menu-main-1__listitems-1__wrapper {
                padding: 2em 0;
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
                background: $color-1--2;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 1000px;
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                }
            }
            a, button, p {
                color: $color-white;
                &:focus {
                    outline-color: $color-white;
                }
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    width: 39rem;
    position: relative;
    height: auto;
    transition: height ease .3s;
    > li {
        display: block;
        border-bottom: 1px solid $color-white;
        // Level 2 and 3
        .menu-main-1__header {
            position: relative;
            .menu-main-1__action {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                text-align: left;
                text-indent: -9999px;
                white-space: nowrap;
                width: 3rem;
                background: transparent;
                overflow: hidden;
                flex-basis: 3rem;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%);
                    content: ">";
                    font-size: 1.3em;
                    font-weight: $bold;
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    margin: 0;
                }
                &[aria-expanded="true"] {
                    &:before {
                        content: "<"
                    }
                }
            }
        }
        > .menu-main-1__header {
            a {
                text-transform: uppercase;
                font-size: 1.9em;
                font-weight: $normal;
                padding-right: 3rem;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: calc(118rem - 78rem);
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: darken($color-1--2, 5%);
            &[aria-hidden="false"] {
                visibility: visible;
                transform: scaleX(1);
                transition: transform ease .4s, -webkit-transform ease .4s;
            }
        }

    }
    .menu-main-1__closing-button {
        display: none;
    }
}

// Level 3
.menu-main-1__listitems-3 {
    padding: 1em;
    > li {
        margin: 0.8em 0;
        > .menu-main-1__header {
            a {
                text-transform: uppercase;
                font-size: 1.7em;
                font-weight: $normal;
                padding-right: 3rem;
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            width: calc(118rem - 78rem);
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: darken($color-1--2, 10%);
            &[aria-hidden="false"] {
                transition: transform ease .4s, visibility ease 0s 0s;
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
}

// Level 4
.menu-main-1__listitems-4 {
    padding: 1em;
    > li {
        margin: 0.8em 0;
        > .menu-main-1__header {
            a {
                font-size: 1.5em;
            }
        }
    }
}
