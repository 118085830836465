// @name Tools
// @description Tools on page and sharing on social media network
.tools {
    width: 30%;
    text-align: right;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $color-1--2;
    }

    .button-2 {
        &:before {
            content: "\e02d";
        }
    }
}

.tools__listitems {
    margin: 0 -6px 0 0;
}

.tools__item {
    display:inline-flex;
    margin: 0 4px;
    font-size:1.2rem;
    line-height:1.2;
    color:$color-1--2;
    &--alt {
        margin-right:22px;
    }

    a {
        display:inline-block;
        vertical-align:middle;
        color:$color-1--2;
        &:hover,
        &:focus {
            color:$color-1--3;
            text-decoration:none;
        }

        > * {
            display:inline-block;
            vertical-align:middle;
        }
    }
}

// @name Share page
.ddm.share-page {
    .ddm__sub-level {
        display: block;
        visibility: hidden;
        top: 100%;
        left: inherit;
        right: 0;
        overflow: hidden;
        z-index: 11;

    }
    .ddm__wrapper {
        transform: translateY(-110%);
        transition: all ease .1s;
    }
    &.ddm--active {
        .ddm__sub-level {
            visibility: visible;
        }
        .ddm__wrapper {
            transform: translateY(0);
            transition: all ease .4s;
        }
    }
}

.share-page {
    .ddm__sub-level {
        text-align: left;
        width: 13em;
    }
    .ddm__wrapper {
        background: $color-1--2;
        color: color-contrast($color-1--2);
        padding: 1em 1.5em;
        a {
            color: color-contrast($color-1--2);
        }
    }
    .ddm__button {
        padding:9px 14px;
        background: $color-1--2;
        &:before {
            color:$color-white;
        }
    }
}

.share-page__item {
    font-size: 1.3em;
    margin: .5em 0;
    @include default-icons-before('\e098', 0 5px 0 0, em(1.4, 1.4), color-contrast($color-1--2), em(-.2, 1.4));
    &.email {
        &:before {
            content: "\e028";
        }
    }
    &.facebook {
        &:before {
            content: "\e098";
        }
    }
    &.twitter {
        &:before {
            content: "\e09a";
        }
    }
    &.google {
        &:before {
            content: "\e0a4";
        }
    }
    &.instagram {
        &:before {
            content: "\e0a1";
        }
    }
    &.linkedin {
        &:before {
            content: "\e0a2";
        }
    }
    &.viadeo {
        &:before {
            content: "\e0a3";
        }
    }
    &.youtube {
        &:before {
            content: "\e09d";
        }
    }
}
