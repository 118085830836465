// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    font-family:$typo-4;
    padding: 56px 0;
    position: relative;
    background-color: $color-3--1;
   //&:after {
   //     content: '';
   //     width: 100%;
   //     height: 100%;
   //     left: 0;
   //     top: 0;
   //     position: absolute;
   //     background-color: #e9eff3;
   //     z-index: -2;
   //}

    &.heading--404 {
        padding: 150px 0 56px 0;
    }

    &__activity {
        font-size: 1.3em;
        margin-top: 1em;
        color: $color-1--1;
    }

    .headding__wrapper-title {
        @extend .wrapper-main;
        margin-bottom: 2em;
    }
    .heading__wrapper {
        @extend .wrapper-main;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        z-index: 10;

        > *:last-child {
            margin-bottom: 0;
        }
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 100%;
        }
    }
    .heading__figure {
        margin-right: 2em;
        display: table;
    }
    .heading__picture {
        display: block;
        margin-bottom: .5em;
        img {
            //max-width: 100%;
            height: auto;
        }
    }
    .heading__caption {
        display: table-caption;
        caption-side: bottom;
    }
    h1 {
        font-weight: $bold;
        font-size: #{$heading__h1__font-size}em;
        line-height: 1.3;
        color: #384249;
    }

    h2 {
        font-weight: $bold;
        font-size: 2.5em;
        line-height: 1.3;
        color: #384249;
        margin-bottom: 2rem;
    }

    h1 + .teaser-2 {
        margin-top: 14px;
        margin-bottom:-3px;
    }
    .subtitle {
        $category__font-size: 1.8;
        font-size: #{$category__font-size}em;
    }
    .subtitle + h1 {
        margin-top: 1px;
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: #fff;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid darken($color-3--1, 20%);
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__wrapper {
            .heading__wrapper-figure {
                order: 2;
            }
            .heading__wrap {
                order: 1;
            }
        }
        .heading__figure {
            margin-right: 0;
            margin-left: 2em;
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: block;
        flex-wrap: inherit;
    }
    .heading__figure {
        margin-right: 0;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
    .heading__wrapper-date {
        width: 16em;
        border-right: 1px solid $color-3--3;
            padding: 0 10px;
        .date-1 {
            margin: 0 auto 10px;
            time {
                margin-top: 0;
            }
        }
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
}
