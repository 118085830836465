// import google font
@if variable-exists(import-google-font-1) {
    @import url($import-google-font-1);
}

@if variable-exists(import-google-font-2) {
    @import url($import-google-font-2);
}

@if variable-exists(import-google-font-3) {
    @import url($import-google-font-3);
}

@if variable-exists(import-google-font-4) {
    @import url($import-google-font-4);
}
// Icon fonts
// create font-face for icon fonts
//@include fontFace(icons-default, 'default-icons-webfont', normal, normal);
//@include fontFace(icons-project, 'icons', normal, normal);
@font-face{
    font-family:"icons-default";
    src:url("#{$font-path}default-icons-webfont.eot");
    src:url("#{$font-path}default-icons-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}default-icons-webfont.woff") format("woff"),
    url("#{$font-path}default-icons-webfont.ttf") format("truetype"),
    url("#{$font-path}default-icons-webfont.svg#icons-default") format("svg");font-weight:normal;
    font-style:normal
}

@font-face{font-family:"icons-project";
    src:url("#{$font-path}icons.eot");
    src:url("#{$font-path}icons.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}icons.woff") format("woff"),
    url("#{$font-path}icons.ttf") format("truetype"),
    url("#{$font-path}icons.svg#icons-project") format("svg");font-weight:normal;
    font-style:normal
}

// @name Body
body {
    font-family: $typo-1;
    font-weight: $normal;
    overflow-x: hidden;
}

// hide SVG symbol
.svg-symbol {
    display: none;
}
