.site-infos {
    width: auto;
    padding-left: 25px;
    &-wrap {
        flex-direction: column;
    }
    &-button {
        display: none;
    }
    .site-infos__wrapper {
        margin-right: 0;
    }
}
