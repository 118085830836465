// @name TYPO3 default content style
.ce-left .ce-gallery, .ce-column {
    float: none;
}

.ce-right .ce-gallery, .ce-left .ce-gallery {
    float: none !important;
}

body .ce-intext.ce-right .ce-gallery {
    margin-left: auto;
    margin-right: auto;
}

body .ce-intext.ce-left .ce-gallery {
    margin-left: auto;
    margin-right: auto;
}
