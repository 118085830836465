// @name Cross menu
.footer {
    .menu-cross {
   position: relative;
   width: 258px;
   padding: 0 40px;
   border: 1px solid #8aa5cd;
   border-width: 0 1px 0 1px;

    }
}

.menu-cross {
    text-transform: uppercase;
    overflow: hidden;

    li {
        vertical-align: top;
        // display: inline-block;
        // width: 49%;
        padding: 2px 10px 7px 17px;
        @include default-icons-absolute-before('\e011', 2rem, $color-1--1, 0, inherit, inherit, 0);
        a {
            display: block;
            color: #fff;
            text-decoration: none;
            font-size: 1.4rem;
            line-height: 1.2em;
            &:hover, &:focus {
                color: #fff;
                span {
                    text-decoration: underline;
                }
            }
        }
        &.active a {
            color: #fff;
            text-decoration: none;
            font-weight: $bold;
        }
    }
   .button-connect {
    display: none;
   }
}
