.web-gallery {
    position: relative;
    padding: 0 0 60px;
    
    .prevNextCaroussel3 {
        
        .prevCaroussel3,
        .nextCaroussel3 {
            width:34px;
            height:34px;
            margin-top:-36px;
            left:-10px;
            
            button {
                &:before {
                    font-size:15px;
                }
            }
        }
        
        .nextCaroussel3 {
            left:auto;
            right: -10px;
        }
    }
}