// @name Signature stratis
.stratis {
    background: $color-1--2;
    width: 100%;
    font-size: 1.1rem;
    color: $color-1--1;
    text-align: right;
    font-weight: 300;
    font-family:$typo-4;
    text-transform: uppercase;
    span {
        display: none;
    }
    a {
        display: inline-block;
        padding: 4px 5px;
        color: #ffffff;
        background: $color-1--3;
        text-decoration: none;
        &:hover, &:focus {
            color: $color-1--3;
            background: #ffffff;
        }
    }
}
