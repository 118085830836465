// Galerie classique (avec vignettes) + Galerie album

// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
    position: relative;
    margin: 6rem auto 6rem;
    width: 708px;
    &__title {
        padding: 0 10px;
    }
}

.slider-galerie {
   margin-bottom: 9px;
}
.carousel-galerie__item {
	opacity: 1 !important;
}
