// # Global
$domain: "bobigny.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";
$import-google-font-3: "https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,500,700,700i";
$import-google-font-4: "https://fonts.googleapis.com/css?family=Ubuntu+Condensed";


$typo-1: 'Open Sans', sans-serif;
$typo-2: 'Open Sans Condensed', sans-serif;
$typo-3: 'Oswald', sans-serif;
$typo-4: 'Ubuntu', sans-serif;
$typo-5: 'Ubuntu Condensed', sans-serif;


$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$path-root: "../" !default;
$path-images: "#{$path-root}Images/";
$image-bg-url: "#{$path-images}bg/";
$image-fancybox-url: "#{$path-images}fancybox/";
$font-path: "#{$path-root}Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000 !default;
$color-white: #fff !default;
$color-red: #fe0000 !default;
$color-green: #278417 !default;
$tarteaucitron-green: #1b870b;

// ## Alert & required
$alert-background: #fcdcde !default;
$alert-title: #ff000d !default;
$alert-color: $color-black !default;

// ## color-1
$color-1--1: #00a8ec !default;
$color-1--2: darken(desaturate(adjust-hue($color-1--1, 19), 23.86), 11.76) !default; //#154b9b
$color-1--3: darken(desaturate(adjust-hue($color-1--1, 18), 24.19), 21.96) !default; //#0f356d
$color-1--4: darken(desaturate(adjust-hue($color-1--1, 22), 29.63), 9.22) !default; //#1c4aa1
$color-1--5: darken(desaturate(adjust-hue($color-1--1, 18), 24.28), 12.35) !default; //#154c98
$color-1--6: darken(desaturate(adjust-hue($color-1--1, 47), 56.44), 14.31) !default; //#332e75
$color-1--7: lighten($color-1--1, 28.24) !default; //#7ddaff
$color-1--8: darken(desaturate(adjust-hue($color-1--1, 1), 1.78), 2.16) !default; //#029adf

// ## color-2
$color-2--1: #495b68 !default;
$color-2--2: darken(desaturate($color-2--1, 4.34), 9.41) !default; //#384249
$color-2--3: darken(desaturate(adjust-hue($color-2--1, -205), 17.51), 20.98) !default; //#232323

// ## Color-3
$color-3--1: #e9eff3 !default;
$color-3--2: darken(desaturate(adjust-hue($color-3--1, -204), 29.41), 16.08) !default; //#c5c5c5
$color-3--3: darken(desaturate(adjust-hue($color-3--1, -204), 29.41), 48.63) !default; //#727272
