// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: auto;
            max-width: 100%;
        }
    }
}

.heading {
	h1 {
		line-height: 1.1;
        color: #384249;
	}
}
