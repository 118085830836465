// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.rte {

    // @name Paragraph
    $content-paragraph__font-size: 1.4;
    p {
        font-size: #{$content-paragraph__font-size}em;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
    }

    ul.co-home-menu {
        display: flex;
        flex-direction: column;
        > li {
            flex: 1 1 100%;
            &:nth-child(odd) {
                margin-right: 0;
            }
        }
    }

    // @name Table
    .table-wrapper {
        position: relative;
    }
    /* .table-wrapper-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
    } */
    .table-wrapper-inner {
        overflow-y: auto;
        width: 100%;
    }
    table {
        caption {
            text-align: left;
        }
        thead {
            th {
                font-size: 1.3rem;
            }
        }
        tbody {
            th {
                font-size: 1.3rem;
            }
        }
        td {
            font-size: 1.4rem;
        }
    }
    ul {
        font-size: 1.4rem;
    }
}

.rte h2,
.rte .h2,
.h2 {
    font-size: 3.4rem;
}
.rte h3,
.rte .h3,
.h3 {
    padding-bottom: 18px;
    margin: 35px 0 14px;
}

