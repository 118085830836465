.report-form {
    padding:10px;
    &:before {
    }

    fieldset {
        display:block;
        padding:31px 11px 20px;

        > * {
            float:none;
            color:$color-white;
        }
    }

    legend {
        display:block;
        position:relative;
        top:14px;
        width:100%;
        font-size:2.2rem;
        margin:6px 0 0;
    }

    label {
        width:100%;
        padding:0 0 24px 22px;
        font-size:1.9rem;
        &:before {
            left:0;
            top:8px;
            background:url(#{$path-images}icons/arrow-right-white.svg) no-repeat;
            width:25px;
            height:27px;
            transform:none;
        }
    }

    select {
        max-width:100%;
        height:40px;
        margin:0 0 19px;
        padding:0 25px 0 9px;
    }

    button {
        display:block;
        margin:0 auto;
        width:104px;
        height:40px;
        &:before {
            left:10px;
        }
    }
}
