.block-location {
	&_close {
		display: none;
	}
	&-map {
		display: block;
	}
	.heading-title {
		width: 100%;
		display: block;
		h2 {
			font-size: 3rem;
			text-align: center; 
			&:before {
				width: 42px;
				height: 11px;
				left: 50%;
				bottom: 11px;
				transform: translateX(-50%); 
				background-size: cover;
			}
		}
	}
	&-container {
		padding-top: 50%;
		margin-bottom: 14px;
	}
	&-address {
		width: 100%;
		float: none;
		padding: 20px 1rem 26px;
		h4 {
			margin-top: 0;
		}
	}
	.list-infos {
		&-img {
			a {
				margin-right: 14px;
			}
		}
		&_title {
			h4 {
				font-size: 2rem;
			}
		} 
		p {
			font-size: 1.5rem !important;
		}
		&__address {
			margin-top: 19px !important;
		}
		.contact {
			padding-top: 15px !important;
			text-align: center;
			.button-2 {
				display: inline-block;
				vertical-align: top;
			}
		}
	}
}