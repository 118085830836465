.web-gallery {
    position: relative;
    padding: 0 17px 55px 0;
    
    .prevNextCaroussel3 {
        
        .prevCaroussel3,
        .nextCaroussel3 {
            margin-top:-42px;
        }
        
        .nextCaroussel3 {
            right: 15px;
        }
    }
}