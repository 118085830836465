.home_gallery-section {
    margin: 0 -20px 22px;
    width: calc(100% + 4rem);

    &_img-holder {
        margin: 0;
        padding: 0;
        border-radius: 0;
    }

    &_container {
        position: relative;
        max-width: none;
        bottom: auto;
        left: auto;
        transform: none;
    }

    &_frame {
        padding: 19px 25px 20px;
    }

    &_block {
        font-size: 2.6rem;
        line-height: 1.2;
    }

    &_title {
        font-size: 1.4rem;
        margin: 0 0 5px;
        &:before {
            width: 39px;
            height: 11px;
            background-size: 100% 100%;
        }
    }
}

.carrousel1 {

    .listItems {
        margin: 0;

        .item {
            padding: 0;
        }
    }

    .button-reset.buttonStop.modeplay {
        margin-left: 16px;
        bottom: 1px;
    }

    .prevNextCarrousel1 {

        .prevCarrousel1,
        .nextCarrousel1 {
            transform: translateY(-50%);
            left: 15px;
            margin-top: 62px;

            button {
                &:before {
                    font-size: 17px;
                }
            }
        }

        .nextCarrousel1 {
            right: 15px;
            left: auto;
        }
    }

    .pagerCarrousel1 {
        width: 52%;
    }
}
