// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    color:$color-black;
    font:$normal 1.5rem/1.3 $typo-4;
    
    p {
        margin:0 0 15px;
    }
    
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 14px;
    }
    
    .bloc-contact__title {
        $bloc-contact__title__font-size: 1.6;
        font-family: $typo-3;
        font-weight: $bold;
        font-size: #{$bloc-contact__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-contact__title__font-size);
        color: $color-black;
        
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    
    .list-infos {
        line-height:1.3;
        margin:0 0 11px;
        
        &__item {
            margin:0 0 4px;
        }
        
        &__address {
            margin:0 0 25px;
        }
    }
    
    .list-infos__wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        
        > * {
            flex: inherit;
        }
        
        .list-infos__listitems + .list-infos__listitems {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
        }
    }
    
    .contact {
        margin:0;
        
        .button-2 {
            padding:17px 15px 15px 42px;
            margin-top: em(1, $button-2__font-size);
            color: #384249;
            font-size: 1.3rem;
            font-family: $typo-4;
            &:before {
                content: "\e034";
                color: $color-1--2;
            }
        }
    }
    
    .mark-text {
        font-size:1.6rem;
        line-height:1.2;
    }
    
    a {
        color:$color-1--2;
        &:hover,
        &:focus {
            //color:$color-1--1;
            text-decoration:none;
        }
        &[href^="tel"] {
            color:#000;
            text-decoration:none;
            &:hover,
            &:focus {
                color:$color-1--1;
            }
        }
    }
}
