.list-type-1--event {
    .list-type-1__picture,
    .list-type-1__wrapper-date,
    .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
    }
    .list-type-1__picture {
        float: none;
    }
    .list-type-1__wrapper-date {
        padding-right: 2em;
        width: 13em;
        border-right: 1px solid $color-3--3;
        .date-1 {
            margin: 0 auto 10px;
            time {
                margin-top: 0!important;
                margin: 0 auto;
            }
        }
    }
    .list-type-1__wrapper {
        padding-left: 2em;
    }
}
