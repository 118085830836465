// @name Videos
.videos {
    margin: 28px 0 63px;

    .videos__title {
        $video__title__font-size: 1.3;
        font-weight: $normal!important;
        font-size: #{$video__title__font-size}em!important;
        color: $color-2--2;
        margin-bottom: em(1.5, $video__title__font-size);
        text-transform: uppercase;
        margin: 33px 0 11px;
    }
}

.videos__item {
    position: relative;
    width: 100%;
    margin: 0 0 25px;
    max-width: 700px;
    video {
        width: 100%;
    }
    img {
        width: 100%;
    }
    iframe,
    .iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.videos__ratio {
    display: block;
    width: 100%;
    height: auto;
}

.ce-textpic {
    .videos,
    .videos__item {
        margin: 0;
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
