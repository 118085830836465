.home-section {
    padding: 35px 0;

    &_heading-box {
        margin: 0 0 28px;

        h2 {
            font-size: 3rem;
            padding-bottom: 28px;
            margin: 0 0 7px;
            &:before {
                width: 42px;
                height: 12px;
                bottom: 5px;
                background-size: 100% 100%;
            }
            &:after {
                height: 12px;
            }

            &.heading--alt {
                font-size: 3rem;
            }
        }

        .btn {
            font-size: 1.2rem;
        }
    }

    &_col-block {
        display: block;
        width: 100%;
        margin: 0 0 -25px;
    }

    &_col-block--news {
        width: 100%;
        margin: 0 0 -39px;

        .home-section_col {
            padding: 0 0 28px;
        }

        .home-section_item {

            .home-section_item_picture {
                margin-bottom: 0;
            }
        }
    }

    &_col {
        width: 100%;
        padding: 0 0 25px;

        .btn {
            font-size: 1.3rem;
        }

        &_title {
            font-size: 2rem;
            margin: 0 0 15px;
        }
    }

    &_item {
        &--lg {

            .home-section_item_picture {
                margin: 0;
            }

            .home-section_item_text-box {
                width: 100%;
                padding: 34px 20px 34px 34px;
                margin: 0;
            }

            .category {
                font-size: 1.3rem;
                margin: 0 0 -1px;
            }
        }
        &--white {

            .home-section_item_text-box {
                padding: 0 10% 18px;
                text-align: center;

                .date-1 {
                    margin-right: auto!important;
                    //transform: translateX(-50%);

                    time {
                        width: 72px;
                        height: 72px;
                        margin: 0;
                        padding: 16px 0 0;
                    }
                }
            }

            .bloc-event {

                &__title {
                    &:after {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        &_picture {
            margin: 0 0 18px;
        }

        h3 {
            font-size: 2.7rem;
            line-height: 1.1;
        }

        h4 {
            margin: 0 0 10px;
        }

        &_text {
            font-size: 2rem;
            padding: 0 0 31px;
            margin: 0 0 23px;
        }

        &_text-holder {
            padding: 17px 21px 32px;
            line-height: 1.4;

            h4 {
                margin-bottom: 3px;
            }
        }

        ul {
            margin: 0 0 17px;

            li {
                padding-top: 11px;
                padding-bottom: 13px;
                &:before {
                    top: 11px;
                }
            }
        }

        ul+.btn {
            font-size: 1.4rem;
        }

        .btn {
            &--alt {
                margin-left: 15px;
                letter-spacing: 0;
                margin-right: 2px;
            }
        }
    }

    .bloc-event {

        // &__title {
        //     &:after {
        //         left:50%;
        //         transform:translateX(-50%);
        //     }
        // }
        .bloc-event__wrapper {
            padding-left: 20px;
        }

        img {
            width: 100%;
            max-height: none;
        }

        .date-1 {
            margin-right: 19px;
        }
    }

    .bloc-download {
        padding: 20px;
        background: #e9eff3;

        &_box {
            display: flex;
            padding: 0;

            .bloc-download_picture {
                width: 134px;
                margin: 0 15px 0 0;

                img {
                    display: block;
                    width: 100%;
                    max-width: none;
                }
            }

            &_text-box {
                padding: 5px 5px 0;
            }
        }

        &_link {
            margin: 0 0 17px;
        }

        &_title {
            margin: 0 0 13px;
        }
    }

    .home-section_promo-box {
        display: block;
    }

    .home-section_promo-box_img_stretch {
        width: 100%;

        img {
            position: static;
            display: block;
            width: 100%;
            height: auto;
            transform: none;
        }
    }

    .home-section_promo-box_text {
        width: 100%;
        padding: 15px 20px 25px;

        h3 {
            font-size: 2.2rem;
            margin: 0 0 7px;
        }

        p {
            margin: 0 0 11px;
        }

        ul {

            >li {
                padding: 0 0 0 16px;
            }
        }
    }

    &_faces-block {
        margin: 0 15px 30px 0;

        &_text-box {
            width: 100%;
            justify-content: center;

            &_title {
                padding: 0 20px;
            }

            .home-section_faces-block_text-box_text {
                padding: 0 20px 0 40px;
            }
        }

        .faces-list {
            width: 100%;
        }
    }
}

.l-blue-section {

    .home-section_col-block {
        overflow: visible;
    }
}

.faces-list {

    li {
        width: 25%;
        &:nth-child(n+5) {
            display: none;
        }
    }

    img {
        display: block;
        width: 100%;
        position: static;
        max-width: none;
        transform: none;
        min-height: 0;
    }
}

.logo-link {
    float: left;
    font-size: 2rem;
    line-height: 1;
    font-weight: $light;
    color: $color-1--2;
    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-1--1;
    }

    >span {
        display: inline-block;
        vertical-align: top;
        font-size: 2.6rem;
        font-weight: $bold;
        margin: -10px 0 0 10px;
    }
}
