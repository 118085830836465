.home-section_social {
   
   .social-list {
       display:block;
       width:100%;
      margin:0 0 10px;
      
      li {
         width:100%;
         padding:0 0 20px;
      }
   }
}