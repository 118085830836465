%home-section_promo-box {
    ul {
        padding: 0;
        margin: 0;

        >li {
            padding: 0 0 0 16px;
            &:before {
                left: 0;
                top: 1px;
                font-weight: $normal;
            }
        }

        a {
            color: #384249;
            text-decoration: none;
            &:hover,
            &:focus {
                color: $color-1--1;
            }
        }
    }
}

.section-main__wrapper {

    .home & {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.l-blue-section {
    background: url(#{$image-bg-url}bg-moments-section.png) repeat;
}

.home-section {
    padding: 50px 0;
    font-family: $typo-4;
    font-size: 1.6rem;
    line-height: 1.2;
    @extend .clear-fix;
    &_background-decor {
        background: url(#{$image-bg-url}dark-bg.png);
        .heading-title {
            h2 {
                color: #fff;
                &:after {
                    background: url(#{$image-bg-url}bg-dark_blue.png);
                }
            }
        }
        .btn {
            color: #fff;
        }
    }
    &_heading-box {
        float: left;
        text-align: right;
        min-width: 0;
        width: 232px;
        margin: -5px 16px 0 0;
        h2 {
            padding-bottom: 35px;
            margin-bottom: 21px;
        }

        .btn {
            display: inline-block;
            vertical-align: top;
            margin: 0 0 7px;
        }
        .btn-pointer_none {
            pointer-events: none;
        }
    }

    &_container {
        width: calc(100% - 260px);
        overflow: hidden;
    }

    &_col-block {
        display: inline-flex;
        flex-wrap: wrap;
        width: calc(100% - 214px);
        margin: 0 -48px -30px 0;
    }

    &_col-block--news {

        .home-section_item {
            &:hover,
            &:focus {
                background: $color-3--1;

                .home-section_item_picture {

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &_col {
        width: 33.33%;
        padding: 0 35px 30px 0;
        &--lg {
            width: 100%;
        }
        &--md {
            width: 71.5%;
        }
        &--xs {
            width: 28%;
        }
        &--md--2 {
            width: 63.5%;
            margin: 0 auto;
        }
        &--xs--2 {
            width: 36.5%;
            margin: 0 auto;
            .home-section_promo-box_txt-a {
                flex-direction: column;
            }
        }

        &_title {
            font-size: 2.2rem;
            line-height: 1.2;
            font-weight: $light;
            color: $color-1--4;
            text-transform: uppercase;
            margin: 0 0 23px;
        }
    }

    &_item {
        &--lg {
            display: flex;
            align-items: center;
            &:hover {
                background: none !important;
            }

            .home-section_item_picture {
                width: 68%;
                margin-bottom: 0;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                &:after {
                    display: none;
                }
            }

            .home-section_item_text-box {
                position: relative;
                z-index: 12;
                margin-left: -45px;
                width: calc(36% + 45px);
                padding: 38px 20px 78px 29px;
                color: $color-white;
                background: $color-1--2;

                p {
                    margin: 0;
                    &+p {
                        margin-top: 15px;
                    }
                }
            }

            .category.category {
                color: $color-white;
                margin: 0 0 -3px;

                a {
                    color: $color-white;
                    &:hover,
                    &:focus {
                        color: $color-1--1 !important;
                        text-decoration: none;
                    }
                }
            }
        }
        &--white {

            .home-section_item_text-box {
                position: relative;
                padding: 28px 20px 38px 64px;
                color: $color-2--2;
                background: $color-white;

                .bloc-event__title {
                    font-size: 3rem;
                    color: $color-2--2;

                    a {
                        color: $color-2--2;
                        &:hover,
                        &:focus {
                            color: $color-1--1;
                        }
                    }
                }

                .category {
                    margin: 0 0 9px;

                    a {
                        color: $color-1--4;
                        &:hover,
                        &:focus {
                            color: $color-1--1;
                        }
                    }
                }

                .bloc-event__title {
                    padding-bottom: 13px;
                }

                .date-1 {
                    position: absolute;
                    display: block;
                    left: -35px;
                    top: 50%;
                    transform: translateY(-50%);

                    time {
                        position: relative;
                        margin: 0 !important;
                        &:before {
                            content: '';
                            position: absolute;
                            left: -15px;
                            top: -15px;
                            bottom: -15px;
                            right: -15px;
                            background: url(#{$image-bg-url}bg-blue-1.png) repeat;
                            z-index: -1;
                        }
                        & + span + time {
                            &:before {
                                transform: none;
                            }
                            &:after {
                                font-family: "icons-default";
                                font-size: 1.2em;
                                color: #fff;
                                position: absolute;
                                top: -8px;
                                right: inherit;
                                bottom: inherit;
                                left: 50%;
                                content: "\e014";
                                line-height: 1;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }
        }

        &_picture {
            display: block;
            position: relative; //height:100%;
            margin: 0 0 13px;
            &:after {
                opacity: 0;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 10;
                box-shadow: inset 0 0 0 10px $color-1--1; //transition:opacity 0.3s ease;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &_text-holder {
            padding: 0 15px 15px;
            border: none;
        }

        h3 {
            font-size: 2.8rem;
            line-height: 1.2;
            font-weight: $light;
            color: $color-white;
            margin: 0 0 8px;

            a {
                color: $color-white;
                &:hover,
                &:focus {
                    color: $color-1--1;
                    text-decoration: none;
                }
            }
        }

        h4 {
            font-size: 1.4rem;
            line-height: 1.2;
            text-transform: uppercase;
            color: $color-1--4;
            margin: 0 0 10px;

            a {
                color: $color-1--4;
                &:hover,
                &:focus {
                    color: $color-1--1;
                    text-decoration: none;
                }
            }
        }

        &_text {
            padding: 0 0 30px;
            margin: 0 0 22px;
            font-size: 2rem;
            line-height: 1;
            font-weight: $light;
            color: #384249;
            border-bottom: 1px solid $color-1--1;
            a {
                color: #384249;
            }
        }

        p {
            margin: 0 0 12px;
        }

        &_link-box {

            >* {
                max-width: 50%;
            }
        }

        .btn {
            font-size: 1.4rem;

            &--alt {
                float: right;
                letter-spacing: -0.5px;
                margin-top: 10px;
            }
        }

        ul {
            margin: 0 0 18px;
            padding: 0;

            li {
                padding-top: 11px;
                padding-bottom: 12px;
                border-bottom: 1px solid #e1e3e4;
                &:first-child {
                    border-top: 1px solid #e1e3e4;
                }
                &:before {
                    top: 11px;
                    left: 0;
                }
            }

            a {
                color: #384249;
                text-decoration: none;
                &:hover,
                &:focus {
                    color: $color-1--1;
                }
            }
        }

        ul+.btn {
            margin-left: 19px;
        }
    }

    &_faces-block {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 37px 40px 0;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: $light;
        background: $color-1--2;

        &_text-box {
            display: flex;
            align-items: stretch;
            width: 34.5%;
            padding: 9px 0px;

            div {
                padding: 0 5% 0 10%;
            }

            &_title {
                align-self: center;
                  flex-basis: 125px;
                font-size: 2.2rem;

                strong {
                    font-weight: $bold;
                }
            }

            &_text {
                position: relative;
                align-self: center;
                flex-basis: 50%;
                a {
                    color: $color-white;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    background: url(#{$path-images}icons/arrow-right.svg) no-repeat;
                    width: 25px;
                    height: 35px;
                    transform: translateY(-50%);
                }
            }
        }

        .faces-list {
            width: 65.5%;
        }
    }

    .bloc-event {

        .bloc-event__wrapper {
            margin: 0;
            padding-bottom: 0;
        }

        .home-section_item_picture {
            margin: 0;
        }
    }

    .category {

        a {
            color: $color-1--4;
            &:hover,
            &:focus {
                color: $color-1--1;
                text-decoration: none;
            }
        }
    }

    .bloc-download {

        &_box {
            display: block;
            padding: 33px 0 0 8px;

            .bloc-download_picture {
                display: inline-block;
                vertical-align: top;
                margin: 0 0 10px;
            }
        }

        &_title {

            a {

                .mark-title {
                    color: $color-black;
                }
            }
        }

        &_link {
            margin: 0 0 7px;
        }
    }

    .home-section_promo-box {
        display: flex;
        width: 100%;
        height: 100%;
        flex-basis: auto;
        background: #e9eff3;
        align-items: stretch;
    }

    .home-section_promo-box_img {
        display: block;
        width: 100%;

        img {
            display: block;
            width: 100%;
            max-width: none;
        }
    }

    .home-section_promo-box_img_stretch {
        position: relative;
        width: 62%;
        overflow: hidden;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .home-section_promo-box_text {
        width: 38%;
        height: 100%;
        padding: 52px 16px 10px;
        font-size: 1.4rem;
        line-height: 1.25;
        font-weight: $light;
        color: #384249;
        background: url(#{$image-bg-url}bg-promo-box.png);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        h3 {
            font-size: 2.2rem;
            color: #384249;
            margin: 0 0 8px;
            flex-basis: auto;

            a {
                color: #384249;
                &:hover,
                &:focus {
                    color: #384249 + 50;
                    text-decoration: none;
                }
            }
        }

        p {
            margin: 0 0 35px;
            flex-basis: auto;
            a {
                color: #000;
                &:hover,
                &:focus {
                    color: #384249 + 50;
                    text-decoration: none;
                }
            }
        }

        .rte {
            margin-top: auto;
            flex-basis: auto;
        }

        @extend %home-section_promo-box
    }

    .home-section_promo-box_txt-a {
        @extend %home-section_promo-box;

        ul {
            padding: 10px 5px;
        }
    }
}

.faces-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        width: 11.11%;
        overflow: hidden;
    }

    img {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 103%;
        min-height: 101%;
        max-width: none;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
    }
}

.logo-link {
    float: left;
    font-size: 2rem;
    line-height: 1;
    font-weight: $light;
    color: $color-1--2;

    >span {
        display: inline-block;
        vertical-align: top;
        font-size: 2.6rem;
        font-weight: $bold;
        margin: -10px 0 0 10px;
    }
}

.home-section_image-gallery {
    width: auto;
}
