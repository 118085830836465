.publication-list {
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    padding:30px 30px 28px;
    background:url(#{$image-bg-url}bg-publication-list.png) repeat;

    &_item-box {
        width:25%;
    }

    &_item-box--md {
        width:68%;
    }

    &_item {
        display:flex;
        margin:0 0 20px;
        font-size:1.4rem;
        font-weight:$light;
        &:last-child {
            margin-bottom:0;
        }

        &--md {
            margin:0;
            height:100%;

            .publication-list_item_link {
                font-size:1.6rem;
            }

            .publication-list_item_title {
                font-size:2.3rem;
            }
        }

        &_picture {
            width:47%;
            margin:0 17px 0 0;
            flex-shrink:0;

            a {
                display:block;
                padding:8px 3px;
                background:$color-white;
                border: 1px solid #d7d9d9;
            }

            img {
                display:block;
                width:100%;
            }
        }

        &_text-box {
            display:flex;
            flex-wrap:wrap;
            align-items:flex-start;
            width:100%;
            flex-direction: column;
            padding:3px 0 0;
        }

        &_title {
            font-size:1.6rem;
            line-height:1.2;
            font-weight:$bold;
            color: #282d33;
            margin:0 0 10px;
            span {
                display: block;
                font-size: 1.4rem;
                font-weight: $light;
                margin: 10px 0 0;
            }
            a {
                display:inline-block;
                vertical-align:top;
                color: #282d33;
                &:hover,
                &:focus {
                    color: $color-1--1;
                    text-decoration:none;
                }
            }
        }

        &_link {
            display:inline-block;
            vertical-align:top;
            font-size:1.3rem;
            text-transform:uppercase;
            color: $color-1--2;
            margin:0 0 5px;
            &:hover,
            &:focus {
                color: $color-1--1;
                text-decoration:none;
            }
        }

        &_link-box {
            display:inline-flex;
            flex-wrap:wrap;
            //align-self:flex-end;
            padding: 18px 0px;
            margin-top: 40px;
            margin-bottom: auto;

            > a {
                font-size:1.3rem;
                padding:16px 5px 14px 35px;
                margin:0 0 2px;

                small {
                    font-size:1.1rem;
                    font-weight:$light;
                }
            }
        }
    }
}
