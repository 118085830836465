.column-bloc.bloc-news {
	.bloc-news {
		&__item {
			display: flex;
			-ms-align-items: center;
			align-items: center;
		}
		&__picture {
			width: 50%;
			margin: 0 34px 0 0;
			img {
				width: 100%;
				height: auto;
			}
		}
		&__wrapper {
			width: 50%;
		}
	}

}