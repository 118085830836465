.rte h2,
.rte .h2,
.h2 {
	font-size: 2rem;
}

.rte h3,
.rte .h3,
.h3 {
	font-size: 1.8rem;
	padding-bottom: 13px;
	&:after {
		right: 0;
		left: 0;
	}
}

.rte h4,
.rte .h4,
.h4 {
	font-size: 1.6rem;
	margin-top: 36px;
}

.rte h5,
.rte .h5,
.h5 {
	font-size: 1.5rem;
	margin-top: 36px;
}

.rte {
	table {
		thead {
			th {

			}
		}
		tbody {
			td {
				text-align: left;
				padding: 8px 24px 8px 4px;
			}
			th {
				text-align: left;
				padding: 8px 24px 8px 11px;
			}
		}
	}
	.table-wrapper {
		margin: 4em 0 44px;
		&-inner {
			padding-bottom: 10px;
		}
	}
    ul.co-home-menu {
        > li {
            h3 {
                img {
                    width: 3.5rem;
                    height: 3.5rem;
                }
                a {
                    font-size: 1.8rem;
                }
            }
        }
    }
}
