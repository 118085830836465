.image-gallery {
    position: relative;
    margin: -4px 0px;
    .listItems {
        .item {
            white-space: normal;
            vertical-align: top;
            a {
                text-decoration: none;
                &.bgInTab {
                    background: #0998d3;
                }
                img {
                    position: static;
                    transform: translate(0, 0);
                    min-width: 0;
                    min-height: 0;
                    width: 100%;
                    display: block;
                    height: auto;
                }
            }
        }
        .image-gallery-lg {
            height: auto;
            min-height: 75vh;
            .item {
                height: 100%;
                a {
                    .image-gallery-video {
                        height: 50%;
                        width: auto;
                        img {
                            height: 100%;
                        }
                    }
                }
                .image-gallery-content {
                    height: 50%;
                    padding-top: 74%;
                    strong {
                        font-size: 2rem;
                    }
                    p {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
    .item {
        height: 50%;
    }
    &-sm {

        min-height: 75vh;
        height: auto;
        .image-gallery-content-c {
            position: static;
            transform: translate(0, 0);
            padding: 10px 0;
        }
    }
    &-content {
        position: static;
        transform: translate(0, 0);
        background: none;
        padding: 4px 0 0;
        height: auto!important;
        min-height: 133px;
        p {
            font-size: 1.3rem;
            margin-bottom: 16px;
        }
        span {
            font-size: 1.3rem;
        }
    }
    &-title {
        font-size: 1.5rem;
    }
    &-video {
        position: relative;
    }
}





.image-gallery-sm:not(:root:root) {
    height: 0; 
   min-height: 80vh;
}


.image-gallery-lg:not(:root:root) {
    height: 0;
   min-height: 80vh;

}
