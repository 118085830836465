.right-menu_block {
    position:fixed;
    right:0;
    top:0;
    bottom:0;
    color:#fff;
    font-family:$typo-4;
    z-index:8000;
    &.ddm--active {

        .right-menu_button {
            right: 329px;
        }

        .right-menu_slide {
            transform:translateX(0);
        }
    }
}

.right-menu_button {
    position:absolute;
    right:-27px;
    top:50%;
    transition:right 0.5s ease;
    background:url(#{$image-bg-url}bg-row.png) repeat;
    border-radius:100%;
    &:after {
        content:'';
        position:absolute;
        left:8px;
        top:8px;
        right:8px;
        bottom:8px;
        background-color: $color-1--2;
        border-radius:100%;
    }

    button {
        width: 141px;
        height: 141px;
        padding:0 15px 0;
        font:$bold 1.4rem/1.1 $typo-4;
        color:#fff;
        text-transform:uppercase;
        text-align:center;
        position:relative;
        z-index:8001;
    }

    svg {
        width:35px;
        height:30px;
        fill:#fff;
        margin:0 0 5px;

        > * {
            fill:#fff;
        }
    }

    div {
        white-space: normal;
        margin: 0 auto;
        width: 90%;
    }
}

.right-menu_title-holder {
    position:relative;
    margin:0 0 15px;
}

.right-menu_title {
    padding:0 22px 0 6px;
    font-size:1.8rem;
    line-height:1.2;
    font-weight:$bold;
    text-transform:uppercase;
}

.right-menu_button--close{
    position:absolute;
    right:0;
    top:0;
    font-size:0;
    line-height:0;
    @include default-icons-after('\e022', 0, 21px, $color-white, 0);
    &:hover,
    &:focus {
        color:$color-red;
        outline-color: currentColor;
        &:after {
            color:$color-red;
        }
    }
}

.right-menu_slide {
    position:fixed;
    right:0;
    top:0;
    bottom:0;
    padding:88px 15px 88px 33px;
    width:350px;
    background:$color-1--2;
    transition:transform 0.5s ease;
    transform:translateX(100%);
    z-index:101;
}

.right-menu_list {
    margin:0;
    padding:0;
    list-style:none;
    font:$normal 1.6rem/1.2 $typo-4;
    color:$color-white;

    li {
        margin:0 0 10px;
    }

    a {
        color:$color-white;
        display:flex;
        align-items:center;
        text-decoration: none;
        outline-color: $color-white;
        &:focus,
        &:hover {
            text-decoration: underline;
            .right-menu_ico-active {
                opacity: 1;
            }
            .right-menu_ico-default {
                opacity: 0;
            }
        }
    }
}

.right-menu_ico-holder {
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    width:50px;
    height:50px;
    margin:0 20px 0 0;
    flex-shrink:0;
    background:url(#{$image-bg-url}bg-right-menu.png) repeat;
    border-radius:100%;
    overflow:hidden;
    &:after {
        content:'';
        position:absolute;
        left:3px;
        top:3px;
        right:3px;
        bottom:3px;
        background:$color-1--2;
        border-radius:100%;
    }
}
.right-menu_ico {
    position: absolute;
    z-index:1;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    transition: opacity 0.3s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    &-active {
        opacity: 0
    }
    &-default {
        opacity: 1;
    }

}
