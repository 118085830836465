.home-section_col-block--hero-gallery {
    overflow: visible;
    margin-bottom: 0;
        margin-top: -24px;
}

.hero-gallery {
    margin: 0 0 29px;
    overflow: visible;
    padding-bottom: 0;
    .carrousel4 {
        width: 100%;
        height: auto;
        .buttonStopHero {
            right: 12px;
            left: auto;
        }
        .listItems {
            width: auto !important;
            max-width: none;
            float: none;
            height: 100%;
            overflow: visible;
            .item {
                height: auto;
                opacity: 1 !important;
                img {
                    position: static;
                    transform: translate(0, 0);
                    min-width: 0;
                    min-height: 0;
                    max-width: none;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .prevNextcarrousel4 {
        display: block;
        position: static;
        width: auto;
        height: auto;
    }
    .prevcarrousel1,
    .nextcarrousel1 {
        position: absolute;
        z-index: 2;
        top: 0;
        left: -10px;
        margin: 0;
        width: 34px;
        button {
            width: 34px;
            height: 34px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: #fff;
            &:before {
                font-family: "icons-default";
                font-size: 1.53846em;
                position: absolute;
                top: 50%;
                right: inherit;
                bottom: inherit;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                color: $color-1--4;
                font-weight: 400;
                content: '\e01a';
                font-size: 1.6rem;
                line-height: 1;
            }
            img {
                display: none;
            }
            &:focus {
                outline-color: #fff;
            }
        }
    }
    .nextcarrousel1 {
        left: auto;
        right: -10px;
        button {
            &:before {
                content: '\e01b';
            }
        }
    }
    &-pagination {
        display: none;
    }
    &-link {
        color: #fff;
    }
    &-content {
        position: relative;
        display: block;
        width: 100%;
        padding: 15px 30px;
        background: #384249;
        color: #fff;
        font-family: $typo-4;
        min-height: 107px;
        &:after {
            position: absolute;
            top: -12px;
            right: 21px;
            width: 0;
            height: 0;
            border-right: 15px solid transparent;
            border-left: 15px solid transparent;
            border-bottom: 12px solid $color-2--2;
            content: '';
        }
        strong {
            font-size: 1.4rem;
            text-transform: uppercase;
            display: block;
        }
        h2 {
            font-size: 2rem;
            font-weight: $light;
            a {
                color: #fff;
            }
        }
    }
}
