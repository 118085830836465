// @name bloc contact

// @name Bloc contact in column
.bloc-download {
    color:$color-black;
    font-family:$typo-4;
    font-size:1.4rem;

    &_box {
        display:flex;
    }

    .bloc-download_picture {
        flex-shrink:0;
        margin:0 25px 15px 0;

        img {
            content:-1px;
        }
    }

    &_link {
        display:inline-block;
        vertical-align:top;
        color: $color-1--2;
        text-transform:uppercase;
        margin:0 0 18px;
    }

    &_title {
        font-size:1.9rem;
        line-height:1.2;
        font-weight:$bold;
        margin:0 0 13px;

      a {
        color:#282d33;

        .mark-title {
            color:$color-1--2;
        }
      }
    }
}
