.moments-form {
    margin:0;

    fieldset {
        margin:0;
        text-align:right;
    }

    label {
        font-size:1.3rem;
        font-weight:$light;
        color:rgba($color-black,0.5);
        display:inline-block;
        margin:0 0 5px;
    }

    .form__field-wrapper {
        margin:0 0 13px;
    }

    select {
        font-size:1.3rem;
        height:40px;
        color: #b3c3ce;
        padding:0 36px 0 8px;
        background:$color-white url(#{$path-images}form/select_right-5.png) no-repeat 100% 50%;
        border-color:$color-white;
    }

    button {
        width:104px;
        height:40px;
        margin:8px 0 14px;
        padding:0 10px 0 31px;
        font-size:1.3rem;
        font-family:$typo-4;
        &:before {
            left:15px;
        }
    }
}
