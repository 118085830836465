.info-box {
    padding: 20px 10px 20px 20px;
    background: $color-3--1;
    margin-bottom: 50px;
    &:after {
        clear: both;
        display: block;
        content: "";
        overflow: hidden;
        visibility: hidden;
        height: 0;
        speak: none;
    }
    &_img {
        float: left;
        margin-right: 35px;
    }
    &_text {
        overflow: hidden;
        a {
            text-decoration: none;
            h3 {
                margin: 0 0 15px;
                font-size: 1.8rem;
            }
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}
