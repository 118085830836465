.moments-form {
    
    fieldset {
        text-align:center;
        
        > div {
            display:block;
            max-width:510px;
            margin:0 auto;
            
            > div {
                width:100%;
                text-align:left;
                &.moments-form_col-md {
                    display:inline-flex;
                    flex-wrap:wrap;
                    width:48%;
                }
            }
        }
    }
    
    label {
        font-size:1.3rem;
        margin:0 0 5px;
    }
    
    .form__field-wrapper {
        margin:0 0 15px;
    }
    
    button {
        margin:0;
    }
}