.block-location {
    margin: 0 0 7.4em;
    .heading-title {
        margin-bottom: 2em;
    }
    &_close {
        display: block;
        position: absolute;
        width: 17px;
        height: 17px;
        right: 8px;
        top: 6px;
        &:after {
            font-family: "icons-default";
            font-size: 1.8rem;
            color: $color-1--5;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            content: "\e022";
            line-height: 1;
        }
    }
}

.block-location-map {
    display: flex;
    position: relative;
    background: $color-3--1;
    .block-location-container {
        overflow: hidden;
        position: relative;
        flex: auto;
        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            max-width: none;
            width: auto;
            height: auto;
        }
    }
}

.block-location-address {
    float: right;
    padding: 24px 30px 34px;
    width: 350px;
    flex-shrink: 0;
}

.list-infos {
    &_title {
        overflow: hidden;
        h4 {
            margin: 0;
            font-size: 2rem;
            color: $color-1--5;
            font-weight: $light;
            font-family: $typo-4;
        }
    }
    .list-infos-wrapper {
        p {
            margin: 0;
            line-height: 1.2;
            a {
                color: $color-2--2;
                text-decoration: none;
            }
        }
        .list-infos-img {
            margin-bottom: 15px;
            a {
                float: left;
                display: block;
                margin-right: 18px;
            }
            &:after {
                clear: both;
                display: block;
                content: "";
                overflow: hidden;
                visibility: hidden;
                height: 0;
                speak: none;
            }
        }
        .list-infos__item {
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1.2;
            padding: 0 0 0 35px;
            margin: 8px 0 14px;
            &:before {
                left: 15px;
                color: $color-1--5;
            }
        }
        p.list-infos__item {
            text-align: left;
        }
        a.list-infos__item {
            font-size: 1.5rem;
            color: #000;
            display: flex;
            position: relative;
            padding-left: 17px;
            &:before {
                font-family: "icons-default";
                font-size: 0.8em;
                color: $color-2--2;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
        .list-infos__phone {
            &:before {
                content: '\e02b';
            }
        }
        .contact {
            margin: 0 12px;
            padding-top: 25px;
            .button-2 {
                padding: 17px 14px 15px 37px;
                text-align: center;
                font-size: 1.3rem;
                font-family: $typo-4;
                color: $color-2--2;
                &:before {
                    content: '\e034';
                    left: 17px;
                }
            }
        }
    }
    &__address {
        &:before {
            content: '\e04d'!important;
            font-size: 1.5rem!important;
        }
    }
    &__email {
        &:before {
            content: '\e029'!important;
            font-size: 1.5rem!important;
        }
    }
}
