.language {
	position: relative;
	margin-top: 0;
	.ddm__title {
		display: block;
	}
	&-title {
		display: block;
		padding: 0 22px 10px 6px;
		color: $color-1--2;
		font-family: $typo-4;
		font-weight: 700;
		text-transform: uppercase;
		position: relative;
		font-size: 1.3rem;
		line-height: 1.2;
		text-decoration: none;
		&:after {
			height: 5px;
			right: 16px;
			background: #22a5df;
			position:absolute;
			bottom: 0;
			left:0;
			content: '';
		}
		&:before {
			border-top: 6px solid $color-1--2;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			position:absolute;
			top:5px;
			right: 0;
			content: '';
		}
	}
	&.ddm--active {
		.language-list {
			display: block;
		}
	}
	&-list {
		display: none;
		position:absolute;
		top:100%;
		left:0;
		background: #fff;
		padding-top: 5px;
		.active {
			display: none;
		}
	}
	&-selection {
		display: none;
	}
}
