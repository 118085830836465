.ddm.ddm__content {
	.ddm__button {
		padding: 0.25em 2em 0.25em 0.5em;
	}
	.ddm__sub-level {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
		h3 {
			font-size: 2rem !important; 
			&:after {
				right: -1rem;
				left: -1rem;
			} 
		} 
	}
}
