.go-to-top {
    display: block;
    width: 100%;
    position: relative;
    right: 1%;
    bottom: 0;
    z-index: 7000;
    height: 0;
    p {
        text-align: right;
        position: absolute;
        right: 0;
        top: -60px;
    }
    a {
        width: 60px;
        height: 60px;
        background: $color-1--2;
        &:before {
            content: "\e015";
            color: #fff;
        }
    }

    &[style*='relative'] {
        height: auto;
        margin-top: -6em;
        p {
            position: static;
        }
    }
}
