// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 1rem;
    padding-right: 1rem;
}

.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}

.header {
    &-bar {
        margin: 0;

        &:only-child {
            margin: 0;
        }

        &-inner {
            padding: 18px 10px 14px;
        }
    }
    &-top-bar {
        padding: 18px 20px 14px;
    }
}

// @name Footer
.footer {
    .footer__wrapper {
        padding-top: 2.6em;
    }
    .footer__wrapper-2 {
        margin: 0;
        flex-direction: column;
        align-items: center;
        > * {
            flex: inherit;
            margin: 0 0 4.3em 0;
        }
    }
}

.section-main__aside {
    .title-3 {
        text-align: left;
        &:before {
            left: 13px;
            transform: none;
        }
    }
}

.top-of-content {
    display: none;
}

.page {
    position: relative;
    .header-bar-inner {
        .language {
            display: none;
        }
    }
    &-decor {
        &_top {
            top: -1698px;

            .home & {
                top:-1618px;
            }
        }
        &_bottom {
            top: 2819px;

            .home & {
                display:none;
            }
        }
    }
}

.readspeaker {
    &__container {
        padding-top: 2rem;
    }

    .home & {
        &__container {
            position: static;
            margin-top: 0;
            padding-bottom: 4rem;
        }

        &__wrapper {
            justify-content: flex-start;

            > div {
                margin-right: 0;
            }
        }
    }
}
