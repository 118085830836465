// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    
    img {
        max-width: 100%;
        height: auto;
    }
    
    .bloc-news__picture {
        display:block;
        margin:0 0 17px;
    }
    
    .bloc-news__title {
        $bloc-news__title__font-size: 1.5;
        font-family: $typo-4;
        font-weight: $light;
        font-size: #{$bloc-news__title__font-size}em;
        margin: 0 0 29px;
        color: $color-black;
        
        a {
            color: #384249;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    
    .bloc-news__wrapper {
        margin:0 0 10px;
    }
    
    .category {
        margin-bottom:9px;
    }

    .link-view {
        position: relative;
        z-index: 99;
        padding: 14px 0 0;
        border-top:1px solid $color-3--2;
    }
}
