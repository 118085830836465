.report-form {
    position:relative;
    padding:10px;
    margin:0;
    color:$color-white;
    background: url(#{$image-bg-url}bg-row.png) repeat;
    z-index:1;
    &:before {
        content:'';
        position:absolute;
        left:10px;
        right:10px;
        top:10px;
        bottom:10px;
        background: $color-1--5;
        z-index:-1;
    }

    fieldset {
        width:100%;
        margin:0;
        padding:19px 20px 17px;

        > * {
            float:left;
            color:$color-white;
        }
    }

    legend {
        width:12%;
        font-size:2.2rem;
        line-height:1.2;
        font-weight:$light;
        text-transform:uppercase;
        margin:6px 0 0;
    }

    label {
        position:relative;
        width:33%;
        margin:-7px 0 0;
        padding:0 0 0 44px;
        font-size:2rem;
        line-height:1.2;
        font-weight:$light;
        &:before {
            content:'';
            position:absolute;
            left:10px;
            top:50%;
            background:url(#{$path-images}icons/arrow-right-white.svg) no-repeat;
            width:25px;
            height:27px;
            transform:translateY(-50%);
        }

        strong {
            font-weight:$bold;
        }
    }

    select {
        position:relative;
        max-width:41%;
        height:40px;
        font-size:1.3rem;
        font-weight:$light;
        color:$color-white;
        border-color:$color-1--3;
        background: $color-1--3 url(#{$path-images}form/select_right-4.png) no-repeat 100% 50%;
        margin:0 10px 0 0;
        padding:0 22px 0 16px;
        &:focus {
            outline: 1px dashed $color-white;
        }
    }

    button {
        width:104px;
        height:40px;
        padding:0 12px 0 27px;
        flex-shrink:0;
        font:$bold 1.3rem/1 $typo-4;
        color:$color-white;
        background:url(#{$image-bg-url}bg-button-report-form.png) repeat;
        &:before {
            color: $color-1--1;
            left:10px;
        }
        &:hover,
        &:focus {
            color:$color-white;
            background:url(#{$image-bg-url}bg-button-report-form.png) repeat;
            opacity:0.85;
        }
    }
}
