.mobile-menu {
	position:absolute;
	top:70px;
	left:0;
	z-index: 101;
	background: #384249;
	width: 100%;
	padding-bottom: 40px;
	.close-btn {
		position:absolute;
		top:100%;
		left:0;
		width: 100%;
		height: 9999px;
		background: rgba(0,0,0,0.5);
		span {
			display: none;
		}
	}
	.menu-show & {
		display: block;
	}
	.menu-main {
		display: block;
		&__level-1 {
			display: block;
			> li {
				position: relative;
				&.active {
					.menu-main__level-2 {
						display: block;
					}
				}
			}
		}
		.menu-main__sublink-level-1 {
			justify-content: center;
			align-items: flex-start;
			height: 90px;
			flex-direction: column;
			background: none !important;
			color: $color-1--1 !important;
			padding: 10px 27px;
			margin: 0 64px 0 0;
			&:before,
			&:after {
				display: none !important;
			}
		}
		.menu-main__sublink-icon {
			width: 64px;
			position:absolute;
			top:0;
			right: 0;
			height: 90px;
			display: block !important;
			visibility: visible !important;
			&:after {
				width: 1px;
				height: 38px;
				background: $color-1--1;
				position:absolute;
				top:50%;
				left:0;
				transform: translate(0, -50%);
				content: '';
			}
			&:before {
				color: #fff;
				font-family: icons-default;
				font-size: 16px;
				line-height: 1;
				position:absolute;
				top:50%;
				left:19px;
				transform: translate(0, -50%);
				content: '\e018';
			}
		}
		.menu-main__level-2 {
			position: relative;
			opacity: 1;
			display: none;
			top: 0;
			left: 0;
			right: 0;
			padding: 19px 10px 1px;
			&:after {
				border-bottom: 16px solid $color-1--4;
				border-left: 16px solid transparent;
				border-right: 16px solid transparent;
				position:absolute;
				top:-16px;
				left:22px;
				content: '';
			}
			.menu-main__subul-level-2 {
				> li {
					padding: 9px 13px;
					margin-bottom: 15px;
					&.active {
						position: relative;
						background: #fff;
						margin-bottom: 0;
						.menu-main__sublink-icon {
							background: $color-1--4;
							border-color: $color-1--4;
							&:before {
								background: #fff;
							}
							&:after {
								display: none;
							}
						}
						.menu-main__sublink-level-2 {
							color: $color-1--4;
							&:after {
								opacity: 1;
							}
						}
					}
				}
				.menu-main__sublink-level-2 {
					font-size: 1.6rem;
					font-weight: 400;
					padding: 5px 0 5px 10px;
					width: calc(100% - 40px);
					&:after {
						background: url(#{$image-bg-url}bg-decor-03.png);
						width: 150px;
						height: 5px;
						bottom: -11px;
						top: auto;
						left:7px;
						z-index: 11;
						opacity: 0;
					}
				}
				.menu-main__sublink-icon {
					left: auto;
					right: 14px;
					top: 9px;
					border: 1px solid #fff;
				}
			}
		}
		.menu-main__level-3 {
			position: relative;
			&-nav {
				padding: 20px 0 0;
				ul {
					li {
						padding-bottom: 0;
						&:not(:last-child) {
							padding-bottom: 12px;
						}
					}
					a {
						font-size: 1.3rem;
						padding: 8px 0 8px 18px;
						&:before {
							display: none;
						}
						&:after {
							content: '\e017';
							font-size: 13px;
							left: 2px;
							top: 10px;
							transform: translate(0, 0);
						}
					}
				}
			}
		}
		&__subblock-level-2,
		.menu-main__closer,
		.menu-main__level-3-back {
			display: none;
		}
		.menu-main__subul--wrapper {
			min-height: 0;
			padding-left: 0;
			border-left: none;
			display: block;
		}
	}
	.search-box {
		margin: 0 0 39px;
		padding: 0 20px;
		width: 100%;
		.ddm > .ddm__sub-level {
			padding: 0;
			background: none;
		}
		.search-box_fields {
			display: block;
			label {
				display: none;
			}
		}
		.search-box__fields-wrapper {
			width: 100%;
			background: none;
			input {
				height: 50px;
				font-size: 16px;
				color: #e9eff3;
				text-overflow: ellipsis;
				overflow: hidden;
				border-bottom: 1px solid #fff;
				padding: 0 20px;
			}
			button {
				margin: 0 0 0 10px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				z-index: 1;
				flex-shrink: 0;
				background: url(#{$image-bg-url}bg-decor-03.png) !important;
				svg {
					display: none;
				}
				&:after {
					width: 46px;
					height: 46px;
					background: #384249;
					border-radius: 50%;
					position:absolute;
					top:50%;
					left:50%;
					transform: translate(-50%, -50%);
					z-index: -1;
					content: '';
				}
				&:before {
					font-family: icons-default;
					font-size: 14px;
					line-height: 1;
					color: #fff;
					position:absolute;
					top:50%;
					left:50%;
					transform: translate(-50%, -50%);
					font-weight: normal;
					content: '\e033';
				}
			}
		}
		.search-box__fields-input {
			&:after {
				display: none;
			}
		}
	}
	.quick-access-btn {
		display: block;
		width: 100%;
		background:#fff url(#{$image-bg-url}bg-decor-03.png);
		position: relative;
		z-index: 1;
		padding: 20px;
		font-size: 15px;
		line-height: 1.2;
		font-weight: 700;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 90px;
		text-transform: uppercase;
		br {
			display: none;
		}
		svg {
			fill: #fff;
			width: 35px;
			height: 30px;
			margin: 0 17px 0 0;
		}
		&:after {
			position:absolute;
			top:8px;
			left: 8px;
			right: 8px;
			bottom: 8px;
			background: $color-1--4;
			z-index: -1;
			content: '';
		}
	}
}
.mobile-component {
	position:absolute;
	top:2px;
	right: -3px;
	height: 70px;
	z-index: 101;
	pointer-events: none;
	display: block;
	.menu-show & {
		pointer-events: auto;
		background: #fff;
		top: 0;
		left: 0;
		right: 0;
		.mobile-component__icon-burger {
			float: left;
			width: 16px;
			height: 12px;
			background: none;
			border-radius: 0;
			margin: 0 10px 0 0;
			span {
				height: 12px;
				border-color: $color-1--6;
				&:after {
					background: $color-1--6;
				}
			}
			&:after {
				display: none;
			}
		}
		.mobile-component__title-text {
			display: block;
		}
		.close-btn {
			display: block;
		}
	}
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 70px;
		padding: 0 20px;
	}
	&__icon-burger {
		display: block;
		width: 57px;
		height: 57px;
		background: url(#{$image-bg-url}bg-decor-03.png);
		border-radius: 50%;
		position: relative;
		pointer-events: auto;
		&:after {
			width: 51px;
			height: 51px;
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			background: $color-1--2;
			border-radius: 50%;
			content: '';
		}
		span {
			width: 16px;
			height: 14px;
			border-top: 2px solid #fff;
			border-bottom: 2px solid #fff;
			position:absolute;
			z-index: 2;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			&:after {
				position:absolute;
				top:50%;
				left:50%;
				width: 16px;
				transform: translate(-50%, -50%);
				height: 2px;
				background: #fff;
				content: '';
			}
		}
	}
	&__title-with-logo {
		display: flex;
		align-items: center;
	}
	&__title-text {
		color: $color-1--6;
		font-size: 1.9rem;
		line-height: 1.2;
		text-transform: uppercase;
		font-weight: 300;
	}
	&__title-text,
	.close-btn {
		display: none;
	}
	.close-btn {
		width: 32px;
		height: 32px;
		background: url(#{$image-bg-url}bg-decor-03.png);
		border-radius: 50%;
		position: relative;
		z-index: 1;
		&:after {
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%);
			width: 28px;
			height: 28px;
			background: #fff;
			border-radius: 50%;
			z-index: -1;
			content: '';
		}
		span {
			position:absolute;
			top:50%;
			left:50%;
			width: 13px;
			height: 13px;
			transform: translate(-50%, -50%);
			overflow: hidden;
			&:after,
			&:before {
				position:absolute;
				top:50%;
				left:50%;
				transform: translate(-50%, -50%) rotate(-45deg);
				width: 2px;
				height: 16px;
				background: $color-1--4;
				content: '';
			}
			&:before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}
}
