.list-document__listitems {
    margin: 0;
    .list-document__item {
        display: block;
        width: 100%;
        margin: 0 0 20px;
        padding-left: 0;
        padding-right: 0;
    }
}

.list-document__title-file {
    margin-bottom: 0;
}