.home-section_social {
   
   .filter-social {
      justify-content:center;
   }
   
   .social-list-head {
      margin:0 0 10px;
   }
   
   .social-list-content {
      padding:12px 0 5px;
      font-weight:$light;
   }
   
   .social-list-head-ico {
      width:32px;
      margin:0 8px 0 0;
   }
}