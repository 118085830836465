.list-news {

    margin: 0;

    .home-section_col-block {
        width: 100%;
        margin: 0;

    }

    .home-section_item--lg .home-section_item_text-box {
        margin-left: 0 ;
        min-height: 0;
        width: 100%;
        margin-top: 0;
    }
}
