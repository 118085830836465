.image-gallery {
    padding-top: 14px;
    .listItems {
        display: flex;
    }
    &-sm {
        display: flex;
        flex-wrap: wrap;
        width: 25%;
        height: 350px;
        .btn-play {
            width: 46px;
            height: 46px;
            transition: opacity 0.4s linear;
            &:after {
                border-width: 5px 0 5px 8px;
            }
        }
    }

    .item {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 50%;
        a {
            display: block;
            position: relative;
            height: 100%;
            background: $color-1--1;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
                height: auto;
                min-width: 101%;
                min-height: 100%;
                max-width: none;
                transition: opacity 0.4s linear;
            }
            &:hover,
            &:focus {
                .image-gallery-content {
                    transform: translate(0, 0);
                }
                img {
                    opacity: 0.15;
                }
                .btn-play {
                    opacity: 0;
                }
            }
        }
    }
      &-lg {
        height: 350px;
        width: 50%;
        .item {
            height: 350px;
        }
    }
    &-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(0, 100%);
        transition: transform 0.4s linear;
        font-family: $typo-4;
        font-weight: $light;
        color: #000;
        text-align: center;
        text-decoration: none;
        .link-plus {
            &:hover {
                text-decoration: underline;
            }
        }
        &-c {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            padding: 0 10px;
            transform: translate(0, -50%);
        }
        p {
            font-size: 1.4rem;
            line-height: 1.2;
            margin: 0 0 25px;
            position: relative;
            color: #000;
        }
        span {
            display: block;
            font-size: 1.1rem;
            color: #000;
            font-weight: $bold;
            text-transform: uppercase;
        }
    }
    &-title {
        font-size: 2rem;
        line-height: 1.2;
        color: $color-2--2;
        display: block;
        text-transform: uppercase;
        margin: 0 0 10px;
        font-weight: 700;
    }
}
