// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4.4;
.title-1 {
    font-family: $typo-1;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $light;
    // text-transform: uppercase;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    font-family: $typo-1;
    font-size: #{$title--2__font-size}em;
    font-weight: $medium;
    text-transform: uppercase;
    color: $color-1--2;
    margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
    font-family: $typo-4;
    font-size: #{$title--3__font-size}rem;
    line-height: 1em;
    font-weight: $light;
    color: $color-3--3;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    padding-bottom: em(1.6, $title--3__font-size);
    border-bottom: 1px solid $color-3--2;
}
