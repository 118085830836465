.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 4em 0 4.9em 0;
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        //transition: all ease .2s;
        h3 {
            font-size: 2.2rem;
            padding-bottom: 19px;
            margin-bottom: 16px;
            &:after {
                width: auto;
            }
        }
        h4 {
            margin-top: 32px;
            font-size: 1.8rem;
        }
    }
    &.ddm--active {
        .ddm__sub-level {
               padding: 25px 10px;
            max-height: 100%;
            background: url(#{$image-bg-url}bg-ddm.png);
            // transition: all ease-in-out 1s;
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        min-height: 45px;
        text-align: left;
        font-size: 2em;
        font-family: $typo-4;
        font-weight: $light;
        background: $color-2--2;
        padding: em(.5, 2) em(4, 2) em(.5, 2) em(1, 2);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        color: $color-white;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 45px;
            background: $color-2--3;
            z-index: 1;
        }
        @include default-icons-absolute-before('\e000', em(1, 2), $color-white, 50%, em(1.8, 1), inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e001";
                color: $color-white;
            }
            &:after {
                background: $color-1--3;
            }
        }
    }
}
