// @name Wrapper with wide 940px
.wrapper-main {
    width: 100%;
}

.section-main__wrapper {
	padding-top: 47px;
}

.wrapper-1160, 
.section-main__wrapper,
.heading .heading__wrapper {
	padding-left: 2rem;
	padding-right: 2rem;
}
.column-bloc {
	width: 100%;
	padding-left: 0;
	margin-bottom: 41px;
}
