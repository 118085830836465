.info-box {
	padding: 0;
	&_img {
		width: 100%;
		float: none;
		margin-bottom: 20px;
		margin-right: 0;
		img {
			width: 100%;
		}
	} 
	&_text {	
		overflow: visible;
		padding: 0 1rem 10px;
		h3 {
			margin-bottom: 17px;
			&:after {
				right: -1rem;
				left: -1rem;
			}
		}
		p {
			font-size: 1.5rem;
			line-height: 1.2;
		}
	}
}