.home-section_social {
    $color-social-fb: #3b5998;
    $color-social-tw: #00acee;
    $color-social-yt: #c4302b;
    $color-social-in: #4c68d7;

    .icons-default {
        width: 20px;
        height: 20px;
    }

    .icon-facebook {
        font-size: 20px;
        content: "\e099";
        color: #fff;
    }

    .filter-social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin: 10px -11px 0 0;
        padding: 0;
        list-style: none;
        font: $bold 1.2rem/1.2 $typo-4;

        li {
            margin: 0 11px 0 0;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border: 3px solid $color-social-fb;
            border-radius: 100%;
            @include default-icons-absolute-before("\e099", 26px, $color-black, 50%, inherit, inherit, 50%, 'icons-default');
            &:before {
                color: $color-social-fb;
                font-weight: $normal;
                transform: translate(-50%, -50%);
            }
            &.active {
                text-decoration: none;
                &:before {
                    color: #fff;
                }
            }
            &:hover,
            &:focus {
                background: $color-3--1 - 20;
                text-decoration: none;
                &:before {
                    color: #fff;
                }
            }
        }

        .filter-social-all {
            width: auto;
            height: auto;
            color: $color-black;
            text-transform: uppercase;
            border: none;
            border-radius: 0;
            &:before {
                display: none;
            }
            &:hover,
            &:focus {
                background: none;
                color: $color-social-yt;
            }
        }

        .filter-social-facebook {
            &.active,
            &:hover,
            &:focus {
                background: $color-social-fb;
            }
        }

        .filter-social-twitter {
            border-color: $color-social-tw;
            &.active,
            &:hover,
            &:focus {
                background: $color-social-tw;
            }
            &:before {
                content: "\e09b";
                color: $color-social-tw;
            }
        }

        .filter-social-youtube {
            border-color: #e62117;
            &.active,
            &:hover,
            &:focus {
                background: #e62117;
            }
            &:before {
                content: "\e09c";
                color: #e62117;
            }
        }

        .filter-social-instagram {
            border-color: $color-social-in;
            &.active,
            &:hover,
            &:focus {
                background: $color-social-in;
            }
            &:before {
                content: "\e0a1";
                color: $color-social-in;
            }
        }
    }

    .social-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 10px;
        padding: 0;
        list-style: none;
        overflow: hidden;

        li {
            width: 33.33%;
            padding: 0 32px 20px 0;
        }
    }

    .social-list-ico {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        @include default-icons-absolute-before("\e099", 16px, $color-black, 50%, inherit, inherit, inherit, 'icons-default');
        &:before {
            color: #fff;
            position: static;
            font-weight: $light;
        }

        &:hover, &:focus {
            text-decoration: none;
        }
    }
    .social-list-ico-facebook {
        background: $color-social-fb;
    }
    .social-list-ico-twitter {
        background: $color-social-tw;
        &:before {
            content: "\e09b";
        }
    }
    .social-list-ico-instagram {
        background: $color-social-in;
        &:before {
            content: "\e0a1";
        }
    }
    .social-list-ico-youtube {
        background: $color-social-yt;
        &:before {
            content: "\e09c";
        }
    }

    .social-list_img-holder {
        display: block;
        position: relative;

        img {
            display: block;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }

    .social-list-youtube {
        .social-list_img-holder {
            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
            &:before {
                width: 50px;
                height: 50px;
                opacity: 0.5;
                background-color: white;
                border-radius: 100%;
            }
            &:after {
                content: "\e075";
                font-family: icons-default;
                font-size: 35px;
                margin: 1px -7px 0 3px;
                color: #e62117;
            }
        }
    }

    .social-list-content {
        padding: 15px 0 5px;
        font: $bold 1.2rem/1.2 $typo-4;
        color: $color-black;

        a {
            color: $color-black;
            &:hover,
            &:focus {
                color: $color-1--2 !important;
                text-decoration: none;
            }
        }
    }

    .social-list-head {
        display: flex;
        margin: 0 0 16px;
    }

    .social-list-head-ico {
        flex-shrink: 0;
        width: 42px;
        margin: 0 10px 0 0;
        border: 1px solid rgba(119, 119, 119, 0.5);

        img {
            display: block;
            width: 100%;
        }
    }

    .social-list-head-text {
        font-size: 1.1rem;
        font-weight: $light;
        color: #717171;
    }

    .social-list-title {
        display: block;
        font-size: 1.3rem;
        font-weight: $bold;
        margin: 0 0 5px;
        &:only-child {
            margin-top: 11px;
        }

        a {
            color: $color-social-fb;
        }
    }

    .social-list-link {
        color: #717171 !important;
        &:hover,
        &:focus {
            color: $color-1--2 !important;
        }
    }

    .social-list-mark-text {
        a {
            color: #154452 !important;
            &:hover,
            &:focus {
                color: $color-1--2 !important;
            }
        }
    }

    p {
        margin: 0;
    }

    .social-list-date {
        font-size: 1rem;
        font-weight: $normal;
        text-transform: uppercase;
        color: $color-social-fb;
        margin: 11px 0 7px;
    }

    .social-list-twitter {

        .social-list-content {

            a {
                color: $color-social-tw;
            }

            p {
                a {
                    color: $color-black;
                }
            }
        }

        .social-list-date {
            color: $color-social-tw;
        }
    }

    .social-list-instagram {

        .social-list-content {

            a {
                color: $color-social-in;
            }

            p {
                a {
                    color: #154452;
                }
            }
        }

        .social-list-date {
            color: $color-social-in;
        }
    }

    .social-list-youtube {
        .social-list-content {
            color: $color-social-yt;

            a {
                color: $color-social-yt;
            }

            p {

                a {
                    color: #154452;
                }
            }
        }

        .social-list-date {
            color: $color-social-yt;
        }
    }
}
