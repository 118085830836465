// @name Document list
// @description List of document in publications list

.list-document {

}

.list-document__listitems {
    flex-direction: column;
    > * {
           width: 50%;
    }
}
