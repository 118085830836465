.quick-access-list {
    line-height:1.1;
    
    a {
        padding: 4px 4px 8px;
    }
    
    svg {
        margin:0 0 5px;
    }
    
    .call {
        width: 25px;
        height: 25px;
    }
    
    .works {
        width: 28px;
        height: 27px;
    }
    
    .maps {
        width: 28px;
        height: 26px;
    }
    
    .building {
        width: 28px;
        height: 29px;
    }
    
    .trash-can {
        width: 25px;
        height: 28px;
    }
    
    .car {
        width: 28px;
        height: 25px;
    }
    
    .menu {
        width: 33px;
        height: 26px;
    }
    
    .service {
        width: 25px;
        height: 27px;
    }
    
    .emergencies {
        width: 28px;
        height: 25px;
    }
}
.quick-access-ico-holder {
    width: 37px;
    height: 42px;
    margin: 0;
}