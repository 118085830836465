.logo {
	margin: 0 10px 0 29px;
	flex-shrink: 0;
	font-size: 1.3em;
	width: 169px;
	a {
		display: block;
	}
	img {
		display: block;
		width: 100%;
		height: auto;
	}
	.home & {
		width: 260px;
		margin: 0 43px 0 0;
	}
}
