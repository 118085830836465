// @name Liste type 3
// @description Directory list
.list-type-3__listitems {
    .list-paginated__wrapper {
        display: block;
        margin: 0;
        > article {
            flex: 0 1 100%;
            max-width: inherit;
            margin: 0 0 3em;
        }
    }
}
.list-type-3--directory {
	.list-paginated__wrapper {
		.list__item {
			width: 100%;
		}
	}
}
