
.subpages-menu__listitems {
    > * {
        flex: 0 1 45.9333%;
}
}

.subpages-menu__picture {
    img {
        max-width: none;
        height: auto;
        width: 100%;
    }
}
