.subscribe-form {
    $this: &;

    .subscribe-form_frame {
        padding:17px 20px 14px 10px;
    }

    .subscribe-form_svg-icon {
        width: 57px;
        height: 47px;
        margin:-3px 0 0;
    }

    .subscribe-form_text {
        width:38%;
        margin:0 3% 0 0;
        padding:0 15px 0 16px;
        font-size:1.7rem;
        &:after {
            background:url(#{$path-images}icons/arrow-right-white.svg) no-repeat;
            width:25px;
            height:27px;
        }

        strong {
            margin:2px 0 0 -10px;
            font-size:2rem;
        }

        > div {
            margin-top:-2px;
        }
    }

    .home-section_subscribe & {
        padding: 30px 20px;

        #{$this}_text {
            font-size: 22px;

            strong {
                @media (max-width: 900px) and (min-width: 769px) {
                    max-width: unset;
                }
            }
        }
        
        #{$this}_frame {
            justify-content: center;
            
            @media (max-width: 900px) and (min-width: 769px) {
                flex-direction: column;
            }
        }

        &_input {
            @media (max-width: 900px) and (min-width: 769px) {
                margin: 25px 0;
            }
        }
    }
}

.home-section_subscribe.home-section_col--lg {
    width: 100%;
    margin: 0;

    @media (max-width: 900px) and (min-width: 769px) {
        &::before,
        &::after {
            display: none;
        }
    }
}
