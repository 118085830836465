.hero-gallery {
    padding-bottom:36px;
    position: relative;
    overflow: hidden;
    width: 100%;
    .carrousel4 {
        height: 360px;
        position: relative;
        z-index: 1;
        width: 949px;
        .buttonStopHero {
                position: absolute;
    left: 265px;
    bottom: 15px;
    width: 7px;
    height: 8px;
    z-index: 12;
    margin: 0 0 0 21px;
        z-index: 222;
        img {
          display: none;
        }
             &:after {
            content: "";
            background: url(#{$path-images}carrousel4/pause.png) no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
        }

        }
        .txt {
            display: none;
        }
        &:after {
            clear: both;
            display: block;
            content: "";
            overflow: hidden;
            visibility: hidden;
            height: 0;
            speak: none;
        }
          .cycle-paused + .buttonStopHero {
        &:after {
            background: url(#{$path-images}carrousel4/play.png) no-repeat;
        }
    }
    }
    .prevNextcarrousel4 {
        display: none;
    }
    .listItems {
        height: 100%;
        width: calc(100% - 310px);
        float: right;
        position: relative;
        overflow: hidden;
        .item {
            height: 100%;
            z-index: 1!important;
            width: 100%;
            img {
                display: block;
                max-width: 110%;
                max-height: 110%;
                width: auto;
                height: auto;
                position: absolute;
                top: 50%;
                bottom: 0;
                left: 50%;
                right: 0;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
    &-content {
        display: none;
    }
    &-pagination {
        width: 310px;
        position: absolute;
        top: 0;
        left: 0px;
        bottom: 0;
        z-index: 100;
        background: $color-2--2;
        li {
            height: 32.33%;
            border-bottom: 1px solid $color-1--7;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            cursor: pointer;
            color: #fff;
            padding: 27px 20px 10px 29px;
            position: relative;
            &:last-child {
                border: none;
            }
            &.cycle-pager-active {
                .hero-gallery-pagination-text {
                    a {
                        color: $color-1--7;
                    }
                }
                &:after {
                    position: absolute;
                    top: 50%;
                    right: -20px;
                    width: 0;
                    transform: translateY(-50%);
                    height: 0;
                    border-top: 25px solid transparent;
                    border-left: 20px solid $color-2--2;
                    border-bottom: 25px solid transparent;
                    content: '';
                }
            }
        }
        &-text {
            width: 100%;
            font-family: $typo-4;
            a {
                color: $color-white;
                text-decoration: none;
                strong {
                    display: block;
                    font-size: 1.4rem;
                    line-height: 1.2;
                    font-weight: $bold;
                    text-transform: uppercase;
                }
                p {
                    font-size: 2rem;
                    line-height: 1.2;
                    font-weight: $light;
                    margin: 0;
                }
            }
        }
    }
}
