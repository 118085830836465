// Newsletter inscription
.newsletter {
    width: 246px;
    margin-right: 78px;
    .newsletter__title {
        margin-bottom: 14px;
        font-size: 1.8rem;
        font-weight: $bold;
        color: #fff;
        text-transform: uppercase;
    }
    form {
        margin: 0;
        label {
            color: #fff;
        }
        input {
            background: none;
            border: none;
            height: 40px;
            color: #b3c3ce;
            font-style: italic;
            font-size: 1.2rem;
            padding: 0 22px;
            &::-webkit-input-placeholder {
                color: #b3c3ce;
            }
            &::-moz-placeholder {
                color: #b3c3ce;
            }
            &:-moz-placeholder {
                color: #b3c3ce;
            }
            &:-ms-input-placeholder {
                color: #b3c3ce;
            }
        }
    }
    fieldset {
        margin-bottom: .5em;
    }
    &__fields {
        position: relative;
    }
    &__fields-wrapper {
        position: relative;
        padding: 0 42px 0 0;
        margin: 0 0 9px;
        background: $color-1--3;
    }
    &__button-wrapper {
        position: absolute;
        bottom: 0px;
        right: 0;
        .button-2 {
            width: 40px;
            height: 40px;
            position: relative;
            padding: 4px;
            svg {
                fill: $color-1--1;
                width: 15px;
                height: 15px;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    .newsletter__links {
        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 14px 0 0px;
            font-size: 1.2rem;
            font-family: $typo-4;
            &:before {
                display: none;
            }
            a {
                color: #fff;
                position: relative;
                padding-left: 15px;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    content: '\e017';
                    transform: translateY(-50%);
                    font-family: "icons-default";
                    color: $color-1--8;
                    font-size: 12px;
                }
            }
        }
    }
}

.newsletter__fields-wrapper,
.newsletter__button-wrapper {
    vertical-align: bottom;
}

.newsletter__links {
}
