.publication-list {
    padding:20px 8px 15px;
    
    &_item-box {
        width:26%;
    }
    
    &_item-box--md {
        width:69%;
    }
    
    &_item {
        
        &_picture {
            width: 48%;
            margin: 0 14px 0 0;
        }
        
        &_text-box {
            padding:0;
        }
        
        &_link {
            font-size:1.4rem;
        }
        
        &_title {
            font-size:1.4rem;
            margin:-2px 0 12px;
        }
    }
}