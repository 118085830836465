.home_gallery-section {
    margin:0 20px;
    width: calc(100% - 4rem);

    &_container {
        max-width: 393px;
        &:before,
        &:after {
            height:42px;
        }
        &:before {
            left:8px;
        }
    }
    
    &_frame {
        padding:19px 25px 27px;
    }
    
    &_block {
        font-size: 4rem;
    }
    
    &_title {
        margin:0 0 10px;
        &:before {
            top:-25px;
        }
    }
}

.carrousel1 {
    
    .listItems {   
        margin: 0 80px;
        .item {
            padding:0 10px 116px;
        }
    }
    
    .prevNextCarrousel1 {
        top: 53%;
        
        .prevCarrousel1,
        .nextCarrousel1 {
            left: 25px;
            margin-top:-70px;
        }
        
        .nextCarrousel1 {
            right: 25px;
            left: auto;
        }
    }
}
