.home-section {
    padding: 45px 0;

    &_heading-box {
        float: none;
        display: block;
        width: 100%;
        text-align: center;
        margin: 0 0 30px;

        br {
            display: none;
        }

        h2 {
            text-align: center;
            padding-bottom: 40px;
            margin: 0 0 13px;
            &:before {
                left: 50%;
                transform: translateX(-50%);
            }
            &:after {
                left: 50%;
                width: 100%;
                max-width: 431px;
                transform: translateX(-50%);
            } // &.heading--alt {
            //     font-size:4rem;
            // }
        }

        .btn {
            font-size: 1.2rem;
            margin: 0;
        }
    }

    &_container {
        width: 100%;
    }

    &_col-block {
        width: calc(100% + 13px);
    }

    &_col-block--news {
        width: calc(100% + 25px);
        margin-bottom: -39px;

        .home-section_col {
            padding: 0 25px 39px 0;
        }

        .home-section_item {
            background: $color-3--1;
            height: 100%;

            .home-section_item_picture {
                margin-bottom: 11px;
            }
        }
    }

    &_col {
        padding: 0 13px 20px 0;
        &--md {
            width: 80%;
        }
        &--xs {
            width: 20%;
        }
        &--md--2 {
            width: 64%;
        }
        &--xs--2 {
            width: 36%;
        }

        .btn {
            font-size: 1.3rem;
        }
    }

    &_title {
        margin: 0 0 20px;
    }

    &_item {
        &--lg {
            display: block;
            background: none !important;

            .home-section_item_picture {
                width: 100%;
            }

            .home-section_item_text-box {
                position: relative;
                z-index: 1;
                width: 371px;
                padding: 38px 20px 78px 29px;
                margin: -63px auto 0;
            }

            .category {
                margin: 0 0 -3px;
            }
        }
        &--white {

            .home-section_item_text-box {
                padding: 0 9% 17px;

                .date-1 {
                    position: static;
                    display: block;
                    width: 60px;
                    margin: 0 auto;
                    transform: translateY(-35px);
                    time {
                        margin: 0;
                    }
                }
            }
        }

        &_picture {
            margin: 0 0 17px;
            &:after {
                display: none;
            }
        }

        &_text-holder {
            padding: 2px 11px 18px;
            line-height: 1.1;

            p {
                margin: 0;
            }
        }

        &_text {
            font-size: 1.7rem;
            padding: 0 0 8px;
            margin: 0 0 14px;
        }

        h4 {
            margin: 0 0 3px;
        }

        ul {
            margin: 0 0 13px;

            li {
                padding-top: 6px;
                padding-bottom: 9px;
                &:before {
                    top: 8px;
                }
            }
        }

        ul+.btn {
            font-size: 1.5rem;
        }

        .btn {
            font-size: 1.3rem;
            margin-top: 13px;
        }
    }

    .bloc-event {

        .bloc-event__wrapper {
            padding-left: 0;
        }
    }

    .bloc-download {

        &_box {
            padding: 0 0 0 16px;

            .bloc-download_picture {
                margin: 0 0 10px;
            }
        }

        &_link {
            margin: 0 0 7px;
        }

        &_title {
            margin: 0 0 9px;
        }
    }
    .home-section_promo-box_section {
        display: flex;
        flex-direction: column;
        height: auto;
        .home-section_promo-box {
            background: url(#{$image-bg-url}bg-promo-box.png);
        }
        .home-section_promo-box_text {
            background: transparent;
        }
    }

    .home-section_promo-box {
        flex: 1;
    }




    .home-section_promo-box_img_stretch {
        width: 62%;
        img {
            width: 100%;
        }
    }

    .home-section_promo-box_text {
        width: 38%;
        padding: 21px 9px 10px 12px;
        font-size: 1.4rem;
        line-height: 1.25;
        flex: auto;

        h3 {
            font-size: 1.7rem;
            margin: 0;
        }

        p {
            margin: 0 0 14px;
        }

        ul {

            >li {
                padding: 0 0 0 16px;
            }
        }
    }

    &_faces-block {
        margin: 0 15px 40px 0;

        &_text-box {
            width: 45%;

            div {
                max-width: 50%;
                padding: 0 25px;
            }
        }

        .faces-list {
            width: 55%;
        }
    }
}

.faces-list {

    li {
        width: 16.667%;
        &:nth-child(n+7) {
            display: none;
        }
    }
}

.logo-link {
    font-size: 1.5rem;

    >span {
        font-size: 2rem;
        margin: -6px 0 0 10px;
    }
}
