.quick-access-list {
    
    a {
        padding: 17px 5px 24px;
        
        > span {
            width:100%;
        }
    }
    
    svg {
        margin:0 0 5px;
    }
    
    .call {
        width: 32px;
        height: 32px;
    }
    
    .works {
        width: 36px;
        height: 35px;
    }
    
    .maps {
        width: 36px;
        height: 34px;
    }
    
    .building {
        width: 36px;
        height: 38px;
    }
    
    .trash-can {
        width: 33px;
        height: 36px;
    }
    
    .car {
        width: 37px;
        height: 33px;
    }
    
    .menu {
        width: 43px;
        height: 34px;
    }
    
    .service {
        width: 33px;
        height: 35px;
    }
    
    .emergencies {
        width: 37px;
        height: 32px;
    }
}